import React, { ReactNode } from 'react';

import ListHeader from '../molecules/ListHeader';
import CourseCard from './CourseCard';
import { CourseModel } from '../../models/course.model';
import { Skeleton } from '@mui/material';
import { ViewMode } from '../../models/user.model';

interface CourseListProps {
  courses?: CourseModel[];
  onSeeAll?: () => void;
  onViewCourse: (course?: CourseModel) => void;
  loading?: boolean;
  emptyComponent?: ReactNode;
  view?: ViewMode;
}

const CourseList = ({
  onSeeAll,
  onViewCourse,
  loading,
  courses,
  emptyComponent,
  view = ViewMode.Learning,
}: CourseListProps) => {
  return (
    <>
      <ListHeader
        title="home.coursesTitle"
        buttonLabel="home.viewAllCourses"
        onClick={onSeeAll}
        disabled={loading || !courses?.length}
      />
      {loading && <Skeleton variant="rectangular" width="100%" height={186} />}
      {!loading && emptyComponent}
      {courses?.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          onViewCourse={onViewCourse}
          view={view}
        />
      ))}
    </>
  );
};

export default CourseList;
