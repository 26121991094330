import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const colors = {
  white: '#FFFFFF',
  lightText: '#3c4756',
  pink: '#F50057',
  deepBlue: '#2F3640',
  lightBlue: '#f4f6f8',
  lightGrey: '#f6f7fb',
  divider: 'rgba(172, 182, 194, 0.2)',
  grey: '#6d7d93',
  purple: '#8A05BE',
  purpleSecondary: '#9f35ca',
  black: 'rgba(0, 0, 0, 0.87)',
  lightGreen: '#ECF9F3',
  green: '#00C853',
};

const spacing = 8;

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Lexend', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: colors.purple,
    },
    secondary: {
      main: colors.purpleSecondary,
    },
    background: {
      default: colors.lightGrey,
    },
    text: {
      secondary: colors.lightText,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, div#root': {
          height: '100%',
        },
        form: {
          width: '100%',
        },
        iframe: {
          border: 0,
        },
        '.MuiSkeleton-root': {
          borderRadius: spacing * 2,
        },
        '.MuiDataGrid-root': {
          backgroundColor: colors.white,
          border: 'unset !important',
          borderRadius: `${spacing * 2}px !important`,
        },
        '.MuiDataGrid-row.Mui-selected': {
          backgroundColor: '#fff !important',
        },
        '.react-player': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
        a: {
          textDecoration: 'unset',
          color: colors.purpleSecondary,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            '&:before': {
              opacity: '1 !important',
            },
            margin: 0,
          },
          '&.MuiPaper-root': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 'initial',
            borderBottom: `1px solid ${colors.purple}`,
            margin: 0,
          },
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            color: colors.purple,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(172, 182, 194, 0)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.lightGreen,
          color: colors.green,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: 600,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: `${spacing * 2}px !important`,
          backgroundColor: colors.white,
          '&.Mui-focused': {
            backgroundColor: colors.white,
          },
        },
        input: {
          padding: spacing * 2,
        },
        adornedStart: {
          '& svg': {
            marginRight: spacing,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '120px !important',
          textTransform: 'capitalize',
        },
        labelIcon: {
          minHeight: 48,
          paddingTop: spacing,
        },
        wrapped: {
          textTransform: 'initial',
          fontSize: 12,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&[class="MuiButtonBase-root"]': {
            borderRadius: spacing * 2,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: 600,
        },
        contained: {
          boxShadow: 'unset',
        },
        outlined: {
          padding: `${spacing / 2}px ${spacing * 2}`,
          fontSize: '12px',
          '&.action': {
            border: `1px solid ${colors.divider}`,
            borderRadius: 2 * spacing,
            color: colors.grey,
            '& .MuiButton-startIcon': {
              color: colors.purple,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.white,
          color: colors.lightText,
          borderRadius: '16px',
        },
        colorSecondary: {
          backgroundColor: colors.purpleSecondary,
          color: colors.white,
          borderRadius: '16px',
        },
        sizeSmall: {
          padding: 0,
          height: '30px',
          width: '30px',
          border: `1px solid ${colors.divider}`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          alignItems: 'center',
        },
        colorDefault: {
          backgroundColor: colors.lightGrey,
          borderBottom: '1px solid rgba(172, 182, 194, 0.2)',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          '&.large': {
            width: spacing * 12,
            height: spacing * 12,
          },
          '&.step': {
            backgroundColor: colors.green,
            fontWeight: 'bold',
          },
        },
        rounded: {
          height: 72,
          width: 72,
          borderRadius: spacing * 2,
          '&.small': {
            width: spacing * 7,
            height: spacing * 7,
          },
          '&.large': {
            width: spacing * 9,
            height: spacing * 9,
          },
          '&.profile': {
            width: spacing * 12,
            height: spacing * 12,
            '& .MuiSvgIcon-root': {
              fontSize: '3rem',
            },
          },
          '&.video': {
            width: spacing * 26,
            height: spacing * 14,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          padding: '16px',
          borderRadius: '16px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderRadius: '16px',
          boxShadow: 'unset',
          width: '100%',
          overflow: 'initial',
          '&.no-padding': {
            padding: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontSize: '0.85rem',
          color: colors.lightText,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingBottom: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
});

export default responsiveFontSizes(defaultTheme);
