import React, { createContext, useContext, useState } from 'react';
import { SnackbarProvider } from 'notistack';

type NotificationProviderProps = { children: React.ReactNode };
export type NotificationContextProps = {
  render: (node: React.ReactElement) => void;
  close: () => void;
};

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [node, setNode] = useState<React.ReactElement | undefined>();
  const render = (child: React.ReactElement) => setNode(child);
  const close = () => setNode(undefined);
  const value = { render, close };

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <NotificationContext.Provider value={value}>
        {children}
        {node}
      </NotificationContext.Provider>
    </SnackbarProvider>
  );
};

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export { NotificationProvider, useNotification };
