import React, { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(publicKey);

const StripeProvider = ({ children }: { children: ReactNode }) => (
  <Elements
    options={{
      fonts: [
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Lexend&display=swap',
        },
      ],
    }}
    stripe={stripePromise}
  >
    {children}
  </Elements>
);

export default StripeProvider;
