import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from '../atoms/TextInput';
import Loading from '../atoms/Loading';
import UserService from '../../services/user.service';
import { UserModel } from '../../models/user.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema } from '../../schemas/user.schema';

interface ChangePasswordFormProps {
  onCancel: () => void;
}

const ChangePasswordForm = ({ onCancel }: ChangePasswordFormProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(changePasswordSchema(t)),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (data: UserModel) => {
    try {
      setLoading(true);
      await UserService.updateUser(data);
      setLoading(false);
      onCancel();
    } catch (error) {
      setLoading(false);
      setError(`${error}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <TextInput
        type="password"
        id="oldPassword"
        label={t('changePassword.oldPassword')}
        name="oldPassword"
        control={control}
      />
      <TextInput
        type="password"
        id="password"
        label={t('changePassword.newPassword')}
        name="password"
        control={control}
      />
      <Box textAlign="right" marginTop={2}>
        <Button onClick={onCancel}>{t('editCourse.cancel')}</Button>
        <Box marginRight={2} display="inline" />
        <Button color="primary" type="submit" disabled={loading}>
          {t('editCourse.submit')}
          <Loading isLoading={loading} />
        </Button>
      </Box>
      {error && (
        <Box marginTop={1}>
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        </Box>
      )}
    </form>
  );
};

export default ChangePasswordForm;
