import React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
  Grid,
  Link,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Logo from '../assets/full-logo-dark.svg';
import Copyright from '../components/molecules/Copyright';
import { signUpSchema } from '../schemas/user.schema';
import { SignUpModel } from '../models/signing.model';
import UserService from '../services/user.service';
import { Trans, useTranslation } from 'react-i18next';
import OutlinedCard from '../components/molecules/OutlinedCard';
import TextInput from '../components/atoms/TextInput';
import ErrorMessage from '../components/atoms/ErrorMessage';
import ContainedButton from '../components/atoms/ContainedButton';
import useFeedback from '../states/FeedbackState';
import { FormErrors } from '../types/form.types';

const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(signUpSchema(t)),
  });
  const { loading, error, setFeedback, showSnackbar } = useFeedback();

  const onSubmit = async (data: SignUpModel) => {
    try {
      setFeedback(true);
      await UserService.signUp(data);
      showSnackbar('signUp.success');
      navigate('/', { replace: true });
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      <Typography variant="h5" gutterBottom>
        {t('signUp.header')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <TextInput
          id="name"
          label={t('user.name')}
          name="name"
          control={control}
        />
        <TextInput
          id="email"
          label={t('user.email')}
          name="email"
          control={control}
        />
        <TextInput
          id="password"
          type="password"
          label={t('user.password')}
          name="password"
          control={control}
        />
        <Box marginTop={1}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="terms"
                defaultValue={false}
                render={({ field }) => <Checkbox {...field} color="primary" />}
              />
            }
            label={
              <Typography variant="subtitle2">
                <Trans
                  i18nKey="signUp.terms"
                  components={{
                    a1: (
                      <a
                        href="https://www.facilittei.com/terms.html"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    a2: (
                      <a
                        href="https://www.facilittei.com/privacy-policy.html"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                />
              </Typography>
            }
          />
          {!!errors.terms?.message && (
            <Typography variant="caption" color="error">
              {(errors as FormErrors).terms?.message}
            </Typography>
          )}
        </Box>
        <ErrorMessage error={error} />
        <Box paddingY={2}>
          <ContainedButton title="signUp.submit" loading={loading} />
        </Box>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link variant="body2" component={RouterLink} to="/">
              {t('signUp.signInHref')}
            </Link>
          </Grid>
        </Grid>
      </form>
      <Box mt={8}>
        <Copyright />
      </Box>
    </OutlinedCard>
  );
};

export default SignUp;
