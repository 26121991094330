import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import {
  CastForEducationOutlined,
  LockOpen,
  Devices,
} from '@mui/icons-material';
import { CourseModel } from '../../models/course.model';
import GridBox from '../atoms/GridBox';
import { useAuth } from '../../providers/AuthProvider';
import { setRedirect } from '../../utils/preferences-storage';
import { trackItemView } from '../../utils/GA/analytics';

interface CoursePriceCardProps {
  course: CourseModel;
}

const CoursePriceCard = ({ course }: CoursePriceCardProps) => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const buyCourseHref = signedUser ? `/checkout/${course.id}` : '/sign-up';

  trackItemView({
    currency: 'BRL',
    value: +(course?.price || '0'),
    items: [
      {
        item_id: `${course.id}`,
        item_name: course.title,
        affiliation: course.profile?.name,
      }
    ]
  });

  const handleBuyCourse = () => {
    !signedUser &&
      setRedirect({ from: '/sign-in', to: `/checkout/${course.id}` });
    navigate(buyCourseHref);
  };

  return (
    <Card>
      <Box textAlign="center" maxWidth={335} margin="auto">
        <Typography variant="overline">
          <Trans i18nKey="course.unlimitedAccess" />
        </Typography>
        <Typography variant="h4">
          <Trans
            i18nKey="currency"
            values={{ price: course.price?.toString().replace('.', ',') }}
          />
        </Typography>
        <Box mt={2} />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleBuyCourse}
        >
          <Trans i18nKey="buyCourse" />
        </Button>
      </Box>
      <Box paddingY={2}>
        <Divider />
      </Box>
      <Typography variant="overline">
        <Trans i18nKey="whatsIncluded" />
      </Typography>
      <GridBox justifyContent="flex-start" paddingTop={1}>
        <CastForEducationOutlined color="action" />
        <Box marginRight={1} />
        <Typography variant="subtitle1">
          <Trans
            i18nKey="course.lessonsQtd"
            count={course?.chapters.flatMap((c) => c.lessons).length}
          />
        </Typography>
      </GridBox>
      <GridBox justifyContent="flex-start" paddingTop={1}>
        <LockOpen color="action" />
        <Box marginRight={1} />
        <Typography variant="subtitle1">
          <Trans i18nKey="course.accessAnyTime" />
        </Typography>
      </GridBox>
      <GridBox justifyContent="flex-start" paddingTop={1}>
        <Devices color="action" />
        <Box marginRight={1} />
        <Typography variant="subtitle1">
          <Trans i18nKey="course.learnAnywhere" />
        </Typography>
      </GridBox>
    </Card>
  );
};

export default CoursePriceCard;
