import React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/full-logo-dark.svg';
import Copyright from '../components/molecules/Copyright';
import { inviteSchema } from '../schemas/user.schema';
import { SignUpModel } from '../models/signing.model';
import UserService from '../services/user.service';
import { useTranslation } from 'react-i18next';
import OutlinedCard from '../components/molecules/OutlinedCard';
import TextInput from '../components/atoms/TextInput';
import ErrorMessage from '../components/atoms/ErrorMessage';
import ContainedButton from '../components/atoms/ContainedButton';
import useFeedback from '../states/FeedbackState';
import { useAuth } from '../providers/AuthProvider';
import HighFiveImg from './../assets/high-five.svg';
import { FormErrors } from '../types/form.types';

const Invite = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { signedUser, setUser } = useAuth();
  const { loading, error, setFeedback } = useFeedback();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(inviteSchema(t)),
  });

  const onSubmit = async (data: SignUpModel) => {
    try {
      setFeedback(true);
      const user = await UserService.signInvite(pathname, data);
      setUser(user);
    } catch (err) {
      setFeedback(false, err);
    }
  };

  if (signedUser) {
    return (
      <OutlinedCard>
        <Typography variant="h4">{t('invite.header')}</Typography>
        <Box paddingY={2}>
          <img alt="No Data" src={HighFiveImg} height={140} />
        </Box>
        <Typography variant="h6" align="center">
          {t('invite.message')}
        </Typography>
        <Box marginY={2} />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
        >
          {t(`invite.start`)}
        </Button>
      </OutlinedCard>
    );
  }

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      <Typography variant="h5" gutterBottom>
        {t('signUp.header')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <TextInput
          id="password"
          type="password"
          label={t('user.password')}
          name="password"
          control={control}
        />
        <Box marginTop={1}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="terms"
                defaultValue={false}
                render={({ field }) => <Checkbox {...field} color="primary" />}
              />
            }
            label={
              <Typography variant="subtitle2">{t('signUp.terms')}</Typography>
            }
          />
          {!!errors.terms?.message && (
            <Typography variant="caption" color="error">
              {(errors as FormErrors).terms?.message}
            </Typography>
          )}
        </Box>
        <ErrorMessage error={error} />
        <Box paddingY={2}>
          <ContainedButton title="signUp.submit" loading={loading} />
        </Box>
      </form>
      <Box mt={8}>
        <Copyright />
      </Box>
    </OutlinedCard>
  );
};

export default Invite;
