import React, { ReactNode } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';

interface ImageCardHeaderProps {
  imageSrc?: string;
  icon?: ReactNode;
  title?: string;
  subtitle?: ReactNode;
  size?: 'small' | 'large';
}

const ImageCardHeader = (props: ImageCardHeaderProps) => (
  <Grid container alignItems="center">
    <Grid item xs="auto">
      <Avatar
        variant="rounded"
        src={props.imageSrc}
        className={props.size || 'large'}
      >
        {props.icon}
      </Avatar>
    </Grid>
    <Grid item xs>
      <Box
        paddingLeft={2}
        height={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h6">{props.title}</Typography>
        <Typography variant="subtitle1" noWrap>
          {props.subtitle}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default ImageCardHeader;
