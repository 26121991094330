import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { PublishOutlined } from '@mui/icons-material';

import CourseService from '../services/course.service';
import { CourseModel } from '../models/course.model';
import { useNotification } from '../providers/NotificationProvider';
import NewChapter from './NewChapter';
import CourseForm from '../components/organisms/CourseForm';
import GridBox from '../components/atoms/GridBox';
import NavHeader from '../components/molecules/NavHeader';
import CoursePreview from '../components/organisms/CoursePreview';
import SimpleDialog from '../components/atoms/SimpleDialog';
import DeleteButton from '../components/atoms/DeleteButton';
import ProgressBox from '../components/atoms/ProgressBox';
import useFeedback from '../states/FeedbackState';
import { ChapterModel } from '../models/chapter.model';

const EditCourse = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [course, setCourse] = useState<CourseModel>();
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState('');

  const feedback = useFeedback();
  const notification = useNotification();

  const fetchCourse = () => {
    CourseService.getCourseById(+id)
      .then(setCourse)
      .catch((e) => (e === 401 ? navigate('/') : setError(e)));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchCourse, [id]);

  if (!course) {
    return <ProgressBox text="editCourse.loading" />;
  }

  if (error) {
    return (
      <Box marginTop={1}>
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      </Box>
    );
  }

  const beforeDelete = () => {
    const { render, close } = notification;
    render(
      <SimpleDialog
        title={t('editCourse.onDelete.dialogTitle')}
        content={t('editCourse.onDelete.dialogContent')}
        onCancel={close}
        onContinue={() => {
          onDelete();
          close();
        }}
      />
    );
  };

  const onDelete = async () => {
    try {
      feedback.setFeedback(true);
      await CourseService.deleteCourse(course.id);
      feedback.showSnackbar('editCourse.onDelete.snackbar');
      navigate('/', { replace: true });
    } catch (err) {
      feedback.setError('editCourse.onDelete.fail', true);
    }
  };

  const onSubmit = async (data: CourseModel) => {
    try {
      feedback.setFeedback(true);
      await CourseService.editCourse(course.id, data);
      data.cover &&
        (await CourseService.setCourseCover({
          ...course,
          cover: data.cover,
        }));

      setCourse({ ...course, ...data });
      setEditable(false);
      feedback.showSnackbar('editCourse.onSubmit.success');
    } catch (err) {
      feedback.setError('editCourse.onSubmit.fail', true);
    }
  };

  const beforePublish = () => {
    const { render, close } = notification;

    const isEmpty = !course.chapters.flatMap((c) => c.lessons || []).length;
    const i18nKey = isEmpty ? 'onPublishEmpty' : 'onPublish';

    render(
      <SimpleDialog
        title={t(`editCourse.${i18nKey}.dialogTitle`)}
        content={t(`editCourse.${i18nKey}.dialogContent`)}
        onCancel={close}
        onContinue={() => {
          onPublish();
          close();
        }}
      />
    );
  };

  const onPublish = async () => {
    try {
      feedback.setFeedback(true);
      course.isPublished = true;
      await CourseService.editCourse(course.id, {
        ...course,
        cover: undefined,
      });
      setCourse(course);
      feedback.showSnackbar('editCourse.onPublish.success');
    } catch (err) {
      feedback.setError('editCourse.onPublish.fail', true);
    }
  };

  const handleChange = (chapters: ChapterModel[]) => {
    setCourse({ ...course, chapters });
  };

  return (
    <>
      <NavHeader goBack={() => navigate(-1)} />
      <Card>
        <GridBox>
          <Typography variant="h5">{t('editCourse.header')}</Typography>
          <DeleteButton onClick={beforeDelete} />
        </GridBox>
        <Box marginBottom={2} />
        <Divider />
        <Box marginBottom={2} />
        {!editable ? (
          <CoursePreview
            course={course}
            onEditCourse={setEditable}
            onViewStudents={() => navigate(`/my-students/${course.id}`)}
            onViewCourse={() => navigate(`/course/${course.id}/${course.slug}`)}
          />
        ) : (
          <CourseForm
            t={t}
            error={t(`${feedback.error}`)}
            loading={feedback.loading}
            onCancel={() => setEditable(false)}
            onSubmit={onSubmit}
            course={course}
          />
        )}
        {!editable && <NewChapter course={course} onUpdate={handleChange} />}
        {!course.isPublished && !editable && (
          <GridBox justifyContent="center" paddingTop={2}>
            <Button
              color="secondary"
              startIcon={<PublishOutlined />}
              onClick={beforePublish}
              disabled={!course?.chapters?.length}
            >
              <Trans i18nKey="editCourse.publish" />
            </Button>
          </GridBox>
        )}
      </Card>
    </>
  );
};

export default EditCourse;
