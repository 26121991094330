import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Grid, Link, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../assets/full-logo-dark.svg';
import Copyright from '../components/molecules/Copyright';
import { signInSchema } from '../schemas/user.schema';
import { SignInModel } from '../models/signing.model';
import UserService from '../services/user.service';
import { useAuth } from '../providers/AuthProvider';
import OutlinedCard from '../components/molecules/OutlinedCard';
import ContainedButton from '../components/atoms/ContainedButton';
import useFeedback from '../states/FeedbackState';
import TextInput from '../components/atoms/TextInput';
import ErrorMessage from '../components/atoms/ErrorMessage';
import { getNewUserEmail } from '../utils/preferences-storage';
import { trackLogin } from '../utils/GA/analytics';

const SignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const { setUser } = useAuth();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(signInSchema(t)),
  });
  const { loading, error, setFeedback } = useFeedback();

  useEffect(() => {
    const email = getNewUserEmail();
    if (email) {
      setValue('email', email);
    }
  }, [setValue]);

  const onSubmit = async (data: SignInModel) => {
    try {
      setFeedback(true);
      const user = await UserService.signIn(data);
      trackLogin();
      setUser(user);
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      <Typography variant="h5" gutterBottom>
        {t('signIn.header')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <TextInput
          autoFocus
          id="email"
          autoComplete="username"
          label={t('user.email')}
          name="email"
          control={control}
        />
        <TextInput
          id="password"
          autoComplete="current-password"
          type="password"
          label={t('user.password')}
          name="password"
          control={control}
        />
        <ErrorMessage error={error} />
        <Box paddingY={2}>
          <ContainedButton title="signIn.submit" loading={loading} />
        </Box>
        <Grid container gap={2}>
          <Grid item xs>
            <Link variant="body2" component={RouterLink} to="/forgot-password">
              {t('signIn.forgotHref')}
            </Link>
          </Grid>
          <Grid item xs textAlign="right">
            <Link variant="body2" component={RouterLink} to="/sign-up">
              {t('signIn.signUpHref')}
            </Link>
          </Grid>
        </Grid>
      </form>
      <Box mt={8}>
        <Copyright />
      </Box>
    </OutlinedCard>
  );
};

export default SignIn;
