import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '@mui/material';
import Loading from './Loading';

interface ContainedButtonProps {
  title?: string;
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const ContainedButton = ({
  title,
  loading = false,
  fullWidth = true,
  disabled = false,
  onClick,
}: ContainedButtonProps) => (
  <Button
    type={onClick ? 'button' : 'submit'}
    fullWidth={fullWidth}
    variant="contained"
    color="primary"
    disabled={loading || disabled}
    onClick={onClick}
  >
    <Trans i18nKey={title} />
    <Loading isLoading={loading} />
  </Button>
);

export default ContainedButton;
