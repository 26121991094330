import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Button, Chip } from '@mui/material';
import {
  Edit,
  PeopleAltOutlined,
  PlayArrow,
  Visibility,
} from '@mui/icons-material';

import { CourseModel } from '../../models/course.model';
import GridBox from '../atoms/GridBox';
import VerticalDivider from '../atoms/VerticalDivider';
import ImageCardHeader from '../molecules/ImageCardHeader';
import moment from 'moment';

interface CoursePreviewProps {
  course: CourseModel;
  onEditCourse: (editable: boolean) => void;
  onViewStudents: () => void;
  onViewCourse: () => void;
}

const CoursePreview = ({
  course,
  onEditCourse,
  onViewStudents,
  onViewCourse,
}: CoursePreviewProps) => {
  return (
    <Box>
      <GridBox justifyContent="flex-start">
        <ImageCardHeader
          imageSrc={course?.cover}
          size="small"
          icon={<PlayArrow />}
          title={course?.title}
          subtitle={moment(course?.createdAt).format('LL')}
        />
        <Box marginRight={2} />
        <VerticalDivider />
        <Chip
          label={
            <Trans
              i18nKey={`editCourse.status.${
                course.isPublished ? 'published' : 'draft'
              }`}
            />
          }
          color={course.isPublished ? 'primary' : 'default'}
          size="small"
        />
      </GridBox>
      <Box display="flex" flexWrap="wrap" marginTop={2}>
        <Button
          variant="outlined"
          className="action"
          startIcon={<Edit />}
          onClick={() => onEditCourse(true)}
        >
          <Trans i18nKey="editCourse.edit" />
        </Button>
        <Box marginRight={2} />
        <Button
          variant="outlined"
          className="action"
          startIcon={<PeopleAltOutlined />}
          onClick={onViewStudents}
        >
          <Trans i18nKey="editCourse.seeStudents" />
        </Button>
        <Box marginRight={2} />
        <Button
          variant="outlined"
          className="action"
          startIcon={<Visibility />}
          onClick={onViewCourse}
        >
          <Trans i18nKey="editCourse.seeCourse" />
        </Button>
      </Box>
    </Box>
  );
};

export default CoursePreview;
