import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import {
  BookmarkBorder,
  CastForEducationOutlined,
  ForumOutlined,
  PeopleAltOutlined,
  PlayArrow,
  AccountBalanceWallet,
} from '@mui/icons-material';

import GridBox from '../atoms/GridBox';
import ImageCardHeader from '../molecules/ImageCardHeader';
import { CourseModel } from '../../models/course.model';
import MenuPopover from '../molecules/MenuPopover';
import CourseMenu from './CourseMenu';
import RoundProgress from '../atoms/RoundProgress';
import { ViewMode } from '../../models/user.model';

interface CourseCardProps {
  course?: CourseModel;
  onViewCourse: (course?: CourseModel) => void;
  view?: ViewMode;
}

const CourseCard = ({
  course,
  onViewCourse,
  view = ViewMode.Learning,
}: CourseCardProps) => {
  const navigate = useNavigate();

  const navTo = (path: string) => () => {
    navigate(`/${path}`);
  };

  return (
    <Box marginBottom={2}>
      <Card>
        <GridBox>
          <ImageCardHeader
            imageSrc={course?.cover}
            icon={<PlayArrow />}
            title={course?.title}
            subtitle={moment(course?.createdAt).fromNow()}
          />
          {course?.progress && <RoundProgress value={course.progress} />}
          {view === ViewMode.Teaching && (
            <MenuPopover
              ContentComponent={() => (
                <CourseMenu
                  onEdit={navTo(`edit-course/${course?.id}`)}
                  onViewStudents={navTo(`my-students/${course?.id}`)}
                />
              )}
            />
          )}
        </GridBox>
        <Box paddingY={2}>
          <Divider />
        </Box>
        <Grid container alignItems="center">
          <Grid item xs={12} sm>
            <GridBox gutterBottom>
              <GridBox justifyContent="flex-start">
                <CastForEducationOutlined color="action" />
                <Box marginRight={1} />
                <Typography variant="subtitle1">
                  {course?.lessons || 0} <Trans i18nKey="home.stats.lessons" />
                </Typography>
              </GridBox>
              <GridBox justifyContent="flex-start">
                <BookmarkBorder color="action" />
                <Box marginRight={1} />
                <Typography variant="subtitle1">
                  {course?.favorites || 0}{' '}
                  <Trans i18nKey="home.stats.favorites" />
                </Typography>
              </GridBox>
            </GridBox>
            <GridBox gutterBottom>
              <GridBox justifyContent="flex-start">
                <PeopleAltOutlined color="action" />
                <Box marginRight={1} />
                <Typography variant="subtitle1">
                  {course?.students || 0}{' '}
                  <Trans i18nKey="home.stats.students" />
                </Typography>
              </GridBox>
              <GridBox justifyContent="flex-start">
                <ForumOutlined color="action" />
                <Box marginRight={1} />
                <Typography variant="subtitle1">
                  {course?.comments || 0}{' '}
                  <Trans i18nKey="home.stats.comments" />
                </Typography>
              </GridBox>
            </GridBox>
          </Grid>
          {course?.sales && course.sales > 0 ? (
            <Grid item xs="auto" sm={3.5}>
              <Box width="fit-content">
                <Box
                  sx={{
                    display: { sm: 'none', xs: 'block' },
                  }}
                  border="1px solid"
                  borderColor="background.default"
                  marginBottom={2}
                />
                <GridBox
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  paddingBottom={1}
                  paddingRight={2}
                >
                  <AccountBalanceWallet color="action" />
                  <Box marginRight={1} />
                  <Box>
                    <Typography variant="subtitle1">
                      <Trans i18nKey="home.stats.sales" />
                    </Typography>
                    <Typography variant="h6" color="action.active">
                      <Trans
                        i18nKey="currency"
                        values={{
                          price: course?.sales?.toFixed(2).replace('.', ','),
                        }}
                      />
                    </Typography>
                  </Box>
                </GridBox>
                <Box
                  sx={{
                    display: { sm: 'none', xs: 'block' },
                  }}
                  border="1px solid"
                  borderColor="background.default"
                  marginY={2}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Box textAlign="center">
          <Button color="secondary" onClick={() => onViewCourse(course)}>
            <Trans i18nKey="home.viewCourse" />
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default CourseCard;
