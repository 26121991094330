import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexRoutes from './IndexRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import UnprotectedRoutes from './UnprotectedRoutes';
import GenericRoutes from './GenericRoutes';
import CourseRoutes from './CourseRoutes';
import LessonRoutes from './LessonRoutes';
import SignIn from '../pages/SignIn';
import NotFound from '../pages/NotFound';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import VerifyEmail from '../pages/VerifyEmail';
import Invite from '../pages/Invite';
import Course from '../pages/Course';
import Home from '../pages/Home';
import MyCourses from '../pages/MyCourses';
import Comments from '../pages/Comments';
import Lesson from '../pages/Lesson';
import Profile from '../pages/Profile';
import MyStudents from '../pages/MyStudents';
import EditCourse from '../pages/EditCourse';
import ResetPassword from '../pages/ResetPassword';
import Checkout from '../components/checkout/Checkout';
import AppToolbar from '../components/molecules/AppToolbar';
import { ViewMode } from '../models/user.model';

const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GenericRoutes />}>
          <Route index element={<IndexRoutes />} />
          <Route element={<UnprotectedRoutes />}>
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify/:code" element={<VerifyEmail />} />
            <Route path="invites/:token" element={<Invite />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<AppToolbar />}>
            <Route path="course/:id" element={<CourseRoutes />}>
              <Route index element={<Course />} />
              <Route path=":slug" element={<Course />} />
            </Route>
            <Route path="checkout">
              <Route path=":courseId" element={<Checkout />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="home" element={<Home />} />
              <Route path="my-learning" element={<MyCourses />} />
              <Route
                path="my-courses"
                element={<MyCourses view={ViewMode.Teaching} />}
              />
              <Route path="comments" element={<Comments />} />
              <Route
                path="chapter/:chapterId/lesson/:id"
                element={<LessonRoutes />}
              >
                <Route index element={<Lesson />} />
              </Route>
              <Route path="account" element={<Profile />} />
              <Route path="my-students">
                <Route index element={<MyStudents />} />
                <Route path=":id" element={<MyStudents />} />
              </Route>
              <Route path="edit-course/:id" element={<EditCourse />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
