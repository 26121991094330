import React from 'react';
import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  error?: string;
}

const ErrorMessage = ({ error }: ErrorMessageProps) =>
  error ? (
    <Box marginTop={1}>
      <Typography color="error" variant="caption">
        {error}
      </Typography>
    </Box>
  ) : null;

export default ErrorMessage;
