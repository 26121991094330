import axios from 'axios';

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

client.interceptors.request.use((config) => {
  const userStr = localStorage.getItem('user') || '';
  config.headers = {
    'Accept-Language': 'pt-br',
    Accept: 'application/json',
  };

  if (userStr) {
    const { token } = JSON.parse(userStr);
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});
