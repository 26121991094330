import React, { ChangeEvent, ReactNode, useEffect } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { applyMask, InputMask } from '../../utils/input-masks';

export interface TextInputProps {
  id?: string;
  label?: string | ReactNode;
  name: string;
  type?: TextFieldProps['type'];
  placeholder?: TextFieldProps['placeholder'];
  multiline?: TextFieldProps['multiline'];
  autoComplete?: TextFieldProps['autoComplete'];
  rows?: TextFieldProps['rows'];
  rowsMax?: TextFieldProps['maxRows'];
  autoFocus?: TextFieldProps['autoFocus'];
  defaultValue?: TextFieldProps['defaultValue'];
  InputLabelProps?: TextFieldProps['InputLabelProps'];
  disabled?: TextFieldProps['disabled'];
  onBlur?: TextFieldProps['onBlur'];
  margin?: TextFieldProps['margin'];
  register?: UseFormRegister<FieldValues>;
  errors?: FieldErrors<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  startIcon?: ReactNode;
  mask?: InputMask;
  max?: number;
  control?: Control;
}

const TextInput = ({
  startIcon,
  mask,
  setValue,
  max,
  name = '',
  control,
  defaultValue,
  margin = 'normal',
  ...props
}: TextInputProps) => {
  useEffect(() => {
    if (defaultValue && mask) {
      applyMask(`${defaultValue}`, { name, mask, setValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (!setValue) {
      return;
    }

    if (mask) {
      applyMask(value, { name, mask, setValue });
    }

    if (max && value.length >= max) {
      setValue(name, value.substring(0, max));
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextField
          {...props}
          fullWidth
          variant="outlined"
          margin={margin}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange(e);
            handleChange(e);
          }}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default TextInput;
