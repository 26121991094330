import { AxiosError } from 'axios';

type RestError = {
  error?: Record<string, unknown>;
  errors?: Record<string, Record<string, unknown>>;
  message?: string;
};

const handleAPIError = (error: unknown) => {
  const errorData = (error as AxiosError<RestError>)?.response?.data;

  if (!errorData) {
    return 'Ocorreu um problema. Por favor, tente novamente em breve.';
  }

  if (errorData?.error) {
    return errorData.error;
  }

  if (errorData?.errors?.email) {
    return errorData.errors.email[0];
  }

  return errorData?.message;
};

export { handleAPIError };
