import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import { Trans } from 'react-i18next';

import GridBox from '../atoms/GridBox';

interface CheckoutTermsProps {
  onBack: () => void;
  onConfirm: () => void;
}

const CheckoutTerms = ({ onBack, onConfirm }: CheckoutTermsProps) => {
  return (
    <Box>
      <GridBox justifyContent="flex-start">
        <ArrowRight />
        <Typography>
          <Trans i18nKey="checkoutTerms.onlyCharge" />
        </Typography>
      </GridBox>
      <GridBox justifyContent="flex-start">
        <ArrowRight />
        <Typography>
          <Trans i18nKey="checkoutTerms.access" />
        </Typography>
      </GridBox>
      <GridBox justifyContent="flex-start">
        <ArrowRight />
        <Typography>
          <Trans i18nKey="checkoutTerms.updates" />
        </Typography>
      </GridBox>
      <Box marginY={2} padding={1} bgcolor="background.default">
        <Typography>
          <Trans
            i18nKey="checkoutTerms.policy"
            components={{
              a1: (
                <a
                  href="https://www.facilittei.com/terms.html"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onBack}>
          <Trans i18nKey="back" />
        </Button>
        <Box marginRight={2} />
        <Button variant="contained" color="primary" onClick={onConfirm}>
          <Trans i18nKey="payment" />
        </Button>
      </Box>
    </Box>
  );
};

export default CheckoutTerms;
