import React from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

import GridBox from '../atoms/GridBox';
import { LessonModel } from '../../models/lesson.model';

interface FavoritesCardProps {
  lesson?: LessonModel;
  onResume: () => void;
}

const FavoritesCard = ({ lesson, onResume }: FavoritesCardProps) => {
  return (
    <Card>
      <GridBox>
        <Box flexGrow={1}>
          <Typography variant="subtitle2" color="inherit">
            {lesson?.title}
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            {lesson?.course?.title} • {lesson?.chapter?.title}
          </Typography>
        </Box>
        <IconButton size="small" onClick={onResume}>
          <PlayArrow color="primary" />
        </IconButton>
      </GridBox>
    </Card>
  );
};

export default FavoritesCard;
