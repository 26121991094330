import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface DeleteButtonProps {
  onClick?: () => void;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <Box color="text.hint">
      <Button
        color="inherit"
        size="small"
        startIcon={<Delete color="error" />}
        onClick={onClick}
      >
        <Trans i18nKey="editCourse.delete" />
      </Button>
    </Box>
  );
};

export default DeleteButton;
