import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorOption, UseFormReturn } from 'react-hook-form';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import StripeInput from './StripeInput';

interface CardDetailsFormProps {
  formMethods: UseFormReturn;
  onBack: () => void;
  onSubmit: (token?: string) => void;
}

const CardDetailsForm = ({
  formMethods,
  onBack,
  onSubmit,
}: CardDetailsFormProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const {
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const handleStripeFormSubmit = async () => {
    const isFormValid = !Object.keys(errors).length;

    if (!stripe || !elements || !isFormValid) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement) {
      const result = await stripe.createToken(cardNumberElement);
      onSubmit(result?.token?.id);
    }
  };

  const handleComplete = (field: string) => () => {
    formMethods.setValue(field, true);
  };

  const handleError = (field: string) => (error?: ErrorOption) => {
    if (error) {
      formMethods.setError(field, error);
      formMethods.setValue(field, undefined);
    } else {
      formMethods.clearErrors(field);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleStripeFormSubmit)}>
      <Grid container spacing={3} paddingY={3}>
        <Grid item xs={12}>
          <StripeInput
            label={t('cardDetailsForm.cardNumber')}
            element={CardNumberElement}
            onComplete={handleComplete('cardNumber')}
            onError={handleError('cardNumber')}
          />
          <input type="hidden" {...formMethods.register('cardNumber')} />
        </Grid>
        <Grid item xs={6}>
          <StripeInput
            label={t('cardDetailsForm.expiration')}
            element={CardExpiryElement}
            onComplete={handleComplete('expirationDate')}
            onError={handleError('expirationDate')}
          />
          <input type="hidden" {...formMethods.register('expirationDate')} />
        </Grid>
        <Grid item xs={6}>
          <StripeInput
            label={t('cardDetailsForm.securityCode')}
            element={CardCvcElement}
            onComplete={handleComplete('securityCode')}
            onError={handleError('securityCode')}
          />
          <input type="hidden" {...formMethods.register('securityCode')} />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button onClick={onBack}>
          <Trans i18nKey="back" />
        </Button>
        <Box marginRight={2} />
        <Button type="submit" variant="contained" color="primary">
          <Trans i18nKey="next" />
        </Button>
      </Grid>
    </form>
  );
};

export default CardDetailsForm;
