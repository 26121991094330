import React, { useEffect, useState } from 'react';
import { Box, Card, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LessonModel } from '../models/lesson.model';
import AccordionList from '../components/molecules/AccordionList';
import { CourseModel } from '../models/course.model';
import LessonView from '../components/organisms/LessonView';
import ProgressBox from '../components/atoms/ProgressBox';
import PlaylistBox from '../components/atoms/PlaylistBox';
import { useAuth } from '../providers/AuthProvider';
import { ViewMode } from '../models/user.model';
import LessonList from '../components/organisms/LessonList';
import { useQuery } from '@tanstack/react-query';

const Lesson = () => {
  const { chapterId, id } = useParams() as { chapterId: string; id: string };
  const [state, setState] = useState<{
    course: CourseModel;
    lesson: LessonModel;
  }>();
  const { signedUser } = useAuth();
  const navigate = useNavigate();

  const { data: lesson, isLoading: isLoadingLesson } = useQuery<LessonModel>(
    [`/chapters/${chapterId}/lessons/${id}`],
    {
      select: (data) => ({
        ...data.current,
        favorited: data.favorited,
        watched: data.watched,
      }),
    }
  );

  const { data: course, isLoading: isLoadingCourse } = useQuery<CourseModel>(
    [`/courses/${+(lesson?.chapter?.course?.id || '')}/chapters`],
    {
      enabled: !!lesson?.id,
    }
  );

  useEffect(() => {
    if (lesson && course) {
      setState({ lesson, course });
    }
  }, [lesson, course]);

  if (isLoadingLesson || isLoadingCourse) {
    return <ProgressBox text="lesson.loading" />;
  }

  if (!state) {
    navigate(-1);
    return null;
  }

  const { chapter } = state.lesson;

  const totalLessons = state.course.chapters.flatMap((c) => c.lessons);
  const activeChapterIdx = state.course.chapters.findIndex(
    (c) => c.id === chapter.id
  );
  const activeLessonIdx = state.course.chapters
    .flatMap((c) => c.lessons)
    .findIndex((c) => c.id === state.lesson.id);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm md>
          <LessonView
            course={state.course}
            lesson={state.lesson}
            view={
              signedUser?.id === state.course.profile?.id
                ? ViewMode.Teaching
                : ViewMode.Learning
            }
            updateLesson={setState}
          />
        </Grid>
        <Grid item sm={4} md={4} lg={4}>
          <PlaylistBox
            next={totalLessons[activeLessonIdx + 1]?.title}
            playlist={state.course.title}
            active={activeLessonIdx + 1}
            total={totalLessons.length}
          >
            <Card className="no-padding">
              <Box paddingX={2}>
                <AccordionList
                  active={activeChapterIdx}
                  data={state.course.chapters.map((chapter) => ({
                    title: chapter.title,
                    content: (
                      <LessonList
                        current={state.lesson.id}
                        lessons={chapter.lessons}
                        watched={state.course.watched}
                      />
                    ),
                  }))}
                />
              </Box>
            </Card>
          </PlaylistBox>
        </Grid>
      </Grid>
    </>
  );
};

export default Lesson;
