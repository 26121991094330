import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LessonModel } from '../../models/lesson.model';
import LessonService from '../../services/lesson.service';
import ProgressBox from '../atoms/ProgressBox';
import LessonView from './LessonView';

interface LessonPreviewProps {
  lessonId: number;
  chapterId: number;
}

const LessonPreview = ({ lessonId, chapterId }: LessonPreviewProps) => {
  const [lesson, setLesson] = useState<LessonModel>();

  useEffect(() => {
    LessonService.getLessonInfo(chapterId, lessonId).then(setLesson).catch();
  }, [chapterId, lessonId]);

  if (!lesson) {
    return (
      <Box paddingY={2}>
        <ProgressBox text="lesson.loading" />
      </Box>
    );
  }

  return <LessonView lesson={lesson} viewOnly />;
};

export default LessonPreview;
