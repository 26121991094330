import React, { useState } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { Trans } from 'react-i18next';

interface FeedbackState {
  loading?: boolean;
  error?: string;
}

const useFeedback = () => {
  const [state, setState] = useState<FeedbackState>({
    loading: false,
    error: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  const setFeedback = (loading = false, error?: string | unknown) => {
    setState({ loading, error: `${error || ''}` });
  };

  const setError = (error = '', notify = false) => {
    setState({ loading: false, error });
    notify && enqueueSnackbar(<Trans i18nKey={error} />, { variant: 'error' });
  };

  const showSnackbar = (message: string, variant: VariantType = 'success') => {
    enqueueSnackbar(<Trans i18nKey={message} />, { variant });
    setState({ loading: false, error: '' });
  };

  return { ...state, setError, setFeedback, showSnackbar };
};

export default useFeedback;
