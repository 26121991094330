import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import { CommentModel } from '../models/comment.model';
import LessonService from '../services/lesson.service';
import FlexibleCard from '../components/atoms/FlexibleCard';
import { NavigateNext } from '@mui/icons-material';
import ListHeader from '../components/molecules/ListHeader';
import ProgressBox from '../components/atoms/ProgressBox';
import Empty from '../components/molecules/Empty';

const Comments = () => {
  const navigate = useNavigate();
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchComments = () => {
    setLoading(true);
    LessonService.getAllComments()
      .then(setComments)
      .finally(() => setLoading(false));
  };

  useEffect(fetchComments, []);

  if (loading) {
    return <ProgressBox text="myComments.loading" />;
  }

  if (!comments.length) {
    return <Empty title="myComments.empty" />;
  }
  return (
    <Container maxWidth="md">
      <ListHeader title="myComments.title" />
      <Box marginBottom={2} />
      <FlexibleCard fullWidth>
        {comments.map((comment, idx) => (
          <Box
            key={comment.id}
            width={1}
            display="flex"
            alignItems="center"
            marginBottom={idx < comments.length - 1 ? 2 : 0}
          >
            <Avatar>{comment.user?.name[0]}</Avatar>
            <Box marginX={2} flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ lineHeight: 0.5 }}
              >
                {comment.user?.name}
              </Typography>
              <Typography variant="caption">
                {`${moment(comment.updatedAt).fromNow()}`}
              </Typography>
              <Typography variant="subtitle2">{comment.description}</Typography>
            </Box>
            <Button
              color="secondary"
              endIcon={<NavigateNext />}
              onClick={() =>
                navigate(
                  `/chapter/${comment.chapterId}/lesson/${comment.lessonId}`
                )
              }
            >
              <Trans i18nKey="view" />
            </Button>
          </Box>
        ))}
      </FlexibleCard>
    </Container>
  );
};

export default Comments;
