import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface LoadingProps {
  isLoading?: boolean;
}

const Loading = ({ isLoading }: LoadingProps) => (
  <>
    {isLoading && (
      <Box
        width={1}
        height={1}
        position="absolute"
        zIndex="modal"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={24} color="secondary" />
      </Box>
    )}
  </>
);

export default Loading;
