import React, { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';

import StudentsGrid from '../components/organisms/StudentsGrid';
import { CourseModel } from '../models/course.model';
import { UserModel } from '../models/user.model';
import CourseService from '../services/course.service';
import Enroll from './Enroll';
import ModalBox from '../components/molecules/ModalBox';
import ProgressBox from '../components/atoms/ProgressBox';
import Empty from '../components/molecules/Empty';

interface MyStudentsState {
  courses: CourseModel[];
  students: UserModel[] | undefined;
  selectedCourse: CourseModel;
  selectedIds?: number[];
}

const MyStudents = () => {
  const params = useParams() as { id: string };

  const [state, setState] = useState<MyStudentsState>();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchStudents = () => {
    setLoading(true);
    CourseService.getCourses()
      .then(async (courses) => {
        const currentCourse =
          (params.id && courses.find((c) => c.id === +params.id)) || courses[0];

        let students: UserModel[] = [];
        if (currentCourse?.id) {
          students = await CourseService.getStudents(currentCourse.id);
        }
        setState({
          courses,
          students,
          selectedCourse: currentCourse,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(fetchStudents, [params.id]);

  if (loading || !state) {
    return <ProgressBox text="myStudents.loading" />;
  }

  if (!state.selectedCourse) {
    return (
      <Empty
        title="myStudents.noCourse.title"
        description="myStudents.noCourse.description"
      />
    );
  }

  const updateList = (selectedCourse: CourseModel) => {
    CourseService.getStudents(selectedCourse.id).then((students) =>
      setState({
        ...state,
        students,
        selectedCourse,
      })
    );
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const id = event.target.value as number;
    const selectedCourse = state.courses.find((c) => c.id === id);

    if (selectedCourse) {
      setState({
        ...state,
        students: undefined,
      });
      updateList(selectedCourse);
    }
  };

  const handleDelete = () => {
    const { selectedCourse, students, selectedIds = [] } = state;
    const selectedStudents =
      students?.filter((s) => selectedIds.includes(s.id)) || [];

    CourseService.annulMany(selectedCourse.id, selectedStudents).then(() =>
      setState({
        ...state,
        students: students?.filter((s) => !selectedIds.includes(s.id)),
        selectedIds: [],
      })
    );
  };

  return (
    <>
      <StudentsGrid
        {...state}
        onChange={handleChange}
        onEnroll={() => setOpenDialog(true)}
        onDelete={handleDelete}
        onSelection={(selectedIds) =>
          setState({
            ...state,
            selectedIds,
          })
        }
      />
      <ModalBox
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="enroll.title"
        maxWidth="sm"
      >
        <Enroll
          course={state.selectedCourse}
          onEnrolled={() => {
            updateList(state.selectedCourse);
            setOpenDialog(false);
          }}
        />
      </ModalBox>
    </>
  );
};

export default MyStudents;
