import React, { useState, MouseEvent } from 'react';
import { IconButton, Popover } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export interface OpenMenuProps {
  handleOpen: (event: MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

export interface MenuContentProps {
  handleClose?: () => void;
}

interface MenuPopoverProps {
  OpenComponent?: (props: OpenMenuProps) => JSX.Element;
  ContentComponent: (props: MenuContentProps) => JSX.Element;
}

const MenuPopover = ({
  OpenComponent = OpenMenu,
  ContentComponent,
}: MenuPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <OpenComponent handleOpen={handleOpen} open={!!anchorEl} />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ContentComponent handleClose={handleClose} />
      </Popover>
    </>
  );
};

const OpenMenu = ({ handleOpen }: OpenMenuProps) => (
  <IconButton onClick={handleOpen} size="large">
    <MoreVert />
  </IconButton>
);

export default MenuPopover;
