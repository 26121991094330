import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Trans } from 'react-i18next';

export type DialogData = {
  title?: string;
  content?: string;
  onCancel?: () => void;
  onContinue?: () => void;
};

const SimpleDialog = ({ title, content, onCancel, onContinue }: DialogData) => {
  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <Trans i18nKey="dialog.cancel" />
        </Button>
        <Button color="primary" onClick={onContinue}>
          <Trans i18nKey="dialog.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
