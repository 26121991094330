import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Button,
  Hidden,
  IconButton,
  Tooltip,
  Card,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  CastForEducationOutlined,
  Edit,
  NavigateNext,
  PeopleAltOutlined,
  PlayArrow,
} from '@mui/icons-material';

import GridBox from '../atoms/GridBox';
import RoundProgress from '../atoms/RoundProgress';
import ImageCardHeader from '../molecules/ImageCardHeader';
import { CourseModel } from '../../models/course.model';

interface DraftCardProps {
  course?: CourseModel;
  onClick?: () => void;
}

const DraftCard = ({ course, onClick }: DraftCardProps) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box marginBottom={2}>
      <Card>
        <Box display="flex" alignItems="center">
          <ImageCardHeader
            imageSrc={course?.cover}
            icon={<PlayArrow />}
            title={course?.title}
            subtitle={moment(course?.createdAt).fromNow()}
          />
          <GridBox
            flexDirection={xs ? 'column' : 'row'}
            width="fit-content"
            justifyContent="center"
          >
            {course?.progress ? (
              <RoundProgress value={course.progress} />
            ) : (
              <>
                <Tooltip arrow title="Aulas">
                  <Box display="flex">
                    <CastForEducationOutlined fontSize="small" color="action" />
                    <Box paddingX={1} color="text.secondary">
                      {course?.lessons || 0}
                    </Box>
                  </Box>
                </Tooltip>
                <Tooltip arrow title="Alunos">
                  <Box display="flex">
                    <PeopleAltOutlined fontSize="small" color="action" />
                    <Box paddingX={1} color="text.secondary">
                      {course?.students || 0}
                    </Box>
                  </Box>
                </Tooltip>
              </>
            )}
            <Hidden only="xs">
              <Button
                color="secondary"
                endIcon={<NavigateNext />}
                onClick={onClick}
              >
                <Trans i18nKey="home.draft.action" />
              </Button>
            </Hidden>
          </GridBox>
          <Hidden smUp>
            <Box marginLeft={1} />
            <IconButton size="small" color="secondary" onClick={onClick}>
              <Edit fontSize="small" />
            </IconButton>
          </Hidden>
        </Box>
      </Card>
    </Box>
  );
};

export default DraftCard;
