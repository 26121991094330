import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { deletePreference, getRedirect } from '../utils/preferences-storage';

const UnprotectedRoutes = () => {
  const location = useLocation();
  const { signedUser } = useAuth();

  if (!signedUser) {
    return <Outlet />;
  }

  const destination = getRedirect(location.pathname);
  destination && deletePreference('redirects');

  return <Navigate to={destination || '/home'} state={{ from: location }} />;
};

export default UnprotectedRoutes;
