import React from 'react';
import { useTranslation } from 'react-i18next';
import { LessonModel } from '../models/lesson.model';
import LessonService from '../services/lesson.service';
import { useNotification } from '../providers/NotificationProvider';
import SimpleDialog from '../components/atoms/SimpleDialog';
import LessonForm from '../components/organisms/LessonForm';
import useFeedback from '../states/FeedbackState';

interface NewLessonProps {
  lesson: LessonModel;
  chapterId: number;
  onDelete: (lesson: LessonModel) => void;
  onUpdate: (lesson: LessonModel) => void;
  onClose: () => void;
}

const EditLesson = ({
  lesson,
  chapterId,
  onClose,
  onDelete,
  onUpdate,
}: NewLessonProps) => {
  const { t } = useTranslation();
  const { render, close } = useNotification();
  const { loading, error, setFeedback } = useFeedback();

  const onSubmit = async (data: LessonModel) => {
    try {
      setFeedback(true);

      if (lesson.id) {
        data.id = lesson.id;
        lesson = await LessonService.updateLesson(chapterId, data);
      } else {
        await LessonService.newLesson(chapterId, data);
      }

      onUpdate(lesson);
      onClose();
    } catch (err) {
      setFeedback(false, err);
    }
  };

  const handleLessonDelete = () => {
    render(
      <SimpleDialog
        title={t('editLesson.onDelete.dialogTitle')}
        content={t('editLesson.onDelete.dialogContent')}
        onCancel={close}
        onContinue={async () => {
          await handleDelete();
          close();
        }}
      />
    );
  };

  const handleDelete = async () => {
    try {
      await LessonService.deleteLesson(lesson);
      onDelete(lesson);
      onClose();
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <LessonForm
      t={t}
      error={error}
      loading={loading}
      onDelete={handleLessonDelete}
      onSubmit={onSubmit}
      lesson={lesson}
    />
  );
};

export default EditLesson;
