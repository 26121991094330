import React, { ReactNode } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryFunction,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { client } from '../utils/client';
import { handleAPIError } from '../utils/error-handling';

const defaultQueryFn: QueryFunction = async ({ queryKey }) => {
  try {
    const { data } = await client.get(`${queryKey[0]}`);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn,
      staleTime: 1000 * 60 * 5,
    },
  },
});

const QueryProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default QueryProvider;
