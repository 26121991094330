import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import UserService from '../services/user.service';
import OutlinedCard from '../components/molecules/OutlinedCard';
import Logo from '../assets/full-logo-dark.svg';
import TextInput from '../components/atoms/TextInput';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from '../schemas/user.schema';
import useFeedback from '../states/FeedbackState';
import ContainedButton from '../components/atoms/ContainedButton';
import ErrorMessage from '../components/atoms/ErrorMessage';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(resetPasswordSchema(t)),
  });

  const { loading, error, setFeedback, showSnackbar } = useFeedback();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const info = query.get('info');

  const onSubmit = async (data: { password: string }) => {
    try {
      if (!token || !info) throw 'Wrong reset link';
      setFeedback(true);
      await UserService.resetPassword(token, info, data);
      showSnackbar('resetPassword.success');
      navigate('/', { replace: true });
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      <Typography variant="h6" align="center">
        {t(`resetPassword.message`)}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <TextInput
          type="password"
          id="password"
          label={t('changePassword.newPassword')}
          name="password"
          control={control}
        />
        <ErrorMessage error={error} />
        <Box textAlign="right" marginTop={2}>
          <ContainedButton title="resetPassword.submit" loading={loading} />
        </Box>
      </form>
    </OutlinedCard>
  );
};

export default ResetPassword;
