import React from 'react';
import { Box, ButtonBase, Chip, Typography } from '@mui/material';
import { LockRounded, RadioButtonUnchecked } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { LessonModel } from '../../models/lesson.model';
import GridBox from '../atoms/GridBox';

interface PreviewLessonListProps {
  lessons: LessonModel[];
  onOpenPreview: (lesson: LessonModel) => void;
}

const PreviewLessonList = ({
  lessons,
  onOpenPreview,
}: PreviewLessonListProps) => {
  const handlePreview = (lesson: LessonModel) => () => {
    if (lesson.isPreview) {
      onOpenPreview(lesson);
    }
  };

  return (
    <Box paddingY={1}>
      {lessons?.map((lesson) => (
        <ButtonBase
          key={lesson.id}
          style={{ width: '100%' }}
          onClick={handlePreview(lesson)}
          disableRipple={!lesson.isPreview}
        >
          <GridBox alignItems="flex-start" paddingY={1}>
            <Box display="flex">
              {lesson.isPreview ? (
                <RadioButtonUnchecked color="action" fontSize="small" />
              ) : (
                <LockRounded color="action" fontSize="small" />
              )}
              <Box marginRight={1} />
              <Typography variant="subtitle2">{lesson.title}</Typography>
            </Box>
            {lesson.isPreview && (
              <Chip label={<Trans i18nKey="preview" />} size="small" />
            )}
          </GridBox>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default PreviewLessonList;
