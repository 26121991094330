import { ProfileModel } from '../models/profile.model';
import { SignInModel, SignUpModel } from '../models/signing.model';
import { UserGroups, UserModel } from '../models/user.model';
import { handleAPIError } from '../utils/error-handling';
import { formDataFromBase64 } from '../utils/file';
import http from '../utils/http';

const signUp = async (user: SignUpModel): Promise<unknown> => {
  try {
    const result = await http.post('/register', {
      ...user,
      group_code: UserGroups.BETA,
    });
    return result;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const signIn = async (credentials: SignInModel): Promise<UserModel> => {
  try {
    const { data } = await http.post('/login', credentials);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const forgotPassword = async (email: unknown): Promise<unknown> => {
  try {
    const result = await http.post('/recover', email);
    return result;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const logout = async () => {
  try {
    await http.delete('/logout');
  } catch (error) {
    throw handleAPIError(error);
  }
};

const updateProfile = async (profile: ProfileModel) => {
  try {
    const { data } = await http.put('/profiles', {
      bio: profile.bio,
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const setProfilePhoto = async (photo = '') => {
  try {
    const formData = await formDataFromBase64('photo', photo);
    await http.post(`/profiles/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getProfile = async () => {
  try {
    const { data } = await http.get('/profiles');
    if (data.profile) {
      return { ...data.profile, photo: '' };
    }

    return { photo: '' };
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getUser = async (): Promise<UserModel> => {
  try {
    const { data } = await http.get('/users');
    return data.user;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const verifyEmail = async (code: string): Promise<string> => {
  try {
    const { data } = await http.get(`/verify/${code}`);
    return data?.user?.email || '';
  } catch (error) {
    throw handleAPIError(error);
  }
};

const resetPassword = async (
  token: string,
  info: string,
  body: { password: string }
) => {
  try {
    const { data } = await http.post(`/reset?token=${token}&info=${info}`, {
      ...body,
      password_confirmation: body.password,
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const signInvite = async (path: string, body: { password: string }) => {
  try {
    const { data } = await http.post(path, {
      ...body,
      password_confirmation: body.password,
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const updateUser = async (user: Partial<UserModel>) => {
  try {
    const { data } = await http.put('/users', user);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const UserService = {
  signUp,
  signIn,
  forgotPassword,
  logout,
  updateProfile,
  setProfilePhoto,
  getProfile,
  verifyEmail,
  getUser,
  updateUser,
  resetPassword,
  signInvite,
};

export default UserService;
