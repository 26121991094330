import React, { ReactNode, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Trans } from 'react-i18next';
import GridBox from './GridBox';
import { Close, ExpandLess } from '@mui/icons-material';

interface PlaylistBox {
  next?: string;
  playlist?: string;
  total?: number;
  active?: number;
  children?: ReactNode;
}

const PlaylistBox = ({
  next,
  playlist,
  total,
  active,
  children,
}: PlaylistBox) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);

  if (upSm) {
    return <>{children}</>;
  }

  return (
    <Box
      width={1}
      position="fixed"
      bottom={0}
      left={0}
      overflow="scroll"
      minHeight="60px"
      maxHeight="60%"
    >
      <Box
        position="fixed"
        width={1}
        bottom={open ? undefined : 0}
        left={0}
        zIndex={1}
        bgcolor={theme.palette.secondary.main}
        color={theme.palette.common.white}
      >
        <GridBox paddingX={2} paddingY={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" color="inherit">
              <Trans
                i18nKey="playlist.next"
                components={{ bold: <strong /> }}
                values={{ next }}
              />
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              {playlist} • {active}/{total}
            </Typography>
          </Box>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setOpen(!open)}
          >
            {!open ? <ExpandLess /> : <Close />}
          </IconButton>
        </GridBox>
      </Box>
      {open && (
        <Box marginTop="60px">
          <Divider />
          <Box bgcolor={theme.palette.common.white}>{children}</Box>
        </Box>
      )}
    </Box>
  );
};

export default PlaylistBox;
