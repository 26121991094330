import { ProfileModel } from './profile.model';

export interface UserModel {
  id: number;
  name: string;
  email: string;
  token: string;
  createdAt: string;
  profile: ProfileModel;
  status?: InviteStatus;
  groups?: UserGroups[];
}

export enum ViewMode {
  Learning = 'learning',
  Teaching = 'teaching',
}

// TODO: create detailed status for tracking invites
export enum InviteStatus {
  NEW_USER = 'newUser',
  ALREADY_USER = 'alreadyUser',
}

export enum UserGroups {
  BETA = 'usuarios_beta',
}
