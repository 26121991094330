import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const IndexRoutes = () => {
  const location = useLocation();
  const { signedUser } = useAuth();
  const to = !signedUser ? '/sign-in' : '/home';

  return <Navigate to={to} state={{ from: location }} />;
};

export default IndexRoutes;
