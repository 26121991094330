import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import EmptyImg from '../../assets/empty.svg';

const Empty = (props: { title?: string; description?: string }) => (
  <Container maxWidth="xs">
    <Box textAlign="center" marginTop={2}>
      <img alt="empty" src={EmptyImg} height={240} />
      <Box marginTop={2} />
      {props.title && (
        <Typography variant="h5">
          <Trans i18nKey={props.title} />
        </Typography>
      )}
      {props.description && (
        <Typography variant="body2">
          <Trans i18nKey={props.description} />
        </Typography>
      )}
    </Box>
  </Container>
);

export default Empty;
