import React from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NoDataImg from './../../assets/no-data.svg';
import { useTranslation } from 'react-i18next';
import { CastForEducationOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const NoData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const handleNewCourse = () => {
    navigate('/new-course');
  };

  return (
    <Box width={1}>
      <Box marginBottom={2}>
        <Typography variant="h5">{t('home.myCourses')}</Typography>
      </Box>
      <Card>
        <Box padding={2}>
          <Typography variant="h5">{t('home.noData.title')}</Typography>
          <Typography variant="h6">{t('home.noData.subtitle')}</Typography>
          <Grid
            container
            justifyContent="space-between"
            direction={xsScreen ? 'column-reverse' : 'row'}
          >
            <Grid item xs={12} sm={3}>
              <Box
                marginTop={2}
                display="flex"
                width={1}
                flexDirection={xsScreen ? 'row' : 'column'}
                alignItems={!xsScreen ? 'flex-start' : undefined}
                justifyContent={xsScreen ? 'center' : undefined}
              >
                <Button
                  color="secondary"
                  startIcon={<CastForEducationOutlined />}
                  onClick={handleNewCourse}
                >
                  {t('home.newCourse')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs>
              <Box
                marginTop={xsScreen ? 2 : 0}
                textAlign={xsScreen ? 'center' : 'right'}
                height={70}
                position="relative"
              >
                <Box position="absolute" bottom={0} right={0}>
                  <img alt="No Data" src={NoDataImg} height={120} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default NoData;
