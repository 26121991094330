import React, { useState } from 'react';
import { Button, ClickAwayListener, InputBase, Tooltip } from '@mui/material';
import ModalBox from '../molecules/ModalBox';
import GridBox from '../atoms/GridBox';
import { Trans } from 'react-i18next';

interface ShareLinkProps {
  link: string;
  onClose: () => void;
}

const ShareLink = ({ link, onClose }: ShareLinkProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setOpen(true);
  };

  return (
    <ModalBox open onClose={onClose} title="Compartilhar" maxWidth="sm">
      <GridBox paddingY={2}>
        <InputBase fullWidth value={link} contentEditable={false} />
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{ disablePortal: true }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<Trans i18nKey="copied" />}
          >
            <Button variant="text" color="primary" onClick={copyToClipboard}>
              <Trans i18nKey="copy" />
            </Button>
          </Tooltip>
        </ClickAwayListener>
      </GridBox>
    </ModalBox>
  );
};

export default ShareLink;
