import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { CourseModel } from '../models/course.model';
import TextInput from '../components/atoms/TextInput';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Loading from '../components/atoms/Loading';
import { CloudUpload, Delete, Description } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { enrollSchema } from '../schemas/enroll.schema';
import { EnrollModel } from '../models/enroll.model';
import { useSnackbar } from 'notistack';
import CourseService from '../services/course.service';

interface EnrollProps {
  course: CourseModel;
  onEnrolled?: () => void;
}

const Enroll = ({ course, onEnrolled }: EnrollProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(enrollSchema(t)),
  });
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [file, setFile] = useState({
    dataURL: '',
    name: '',
  });

  const handleUpload = (input: React.ChangeEvent<HTMLInputElement>) => {
    if (input.target.files && input.target.files[0]) {
      const file = input.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        e.target &&
          setFile({
            dataURL: e.target.result as string,
            name: file.name,
          });
      };
    }
  };

  const onEnroll = async () => {
    if (file.name) {
      try {
        setLoading(true);
        setError('');
        await CourseService.enrollMany(course.id, file.dataURL);
        snackbar.enqueueSnackbar(t('enroll.snackbar.onEnrolledMany'));
        onEnrolled && onEnrolled();
      } catch (err) {
        setError(`${err}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = async (enroll: EnrollModel) => {
    try {
      setLoading(true);
      setError('');
      await CourseService.enroll(course.id, enroll);
      snackbar.enqueueSnackbar(t('enroll.snackbar.onEnrolled'));
      onEnrolled && onEnrolled();
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>, onEnroll)}
    >
      <Box
        maxWidth="sm"
        padding={2}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {!file.name ? (
          <>
            <TextInput
              autoFocus
              label={t('enroll.name')}
              name="name"
              control={control}
            />
            <TextInput
              label={t('enroll.email')}
              name="email"
              control={control}
            />
          </>
        ) : (
          <>
            <Box paddingBottom={1}>
              <Description fontSize="large" color="action" />
            </Box>
            <Typography variant="subtitle1" align="center">
              {file.name}
            </Typography>
          </>
        )}
        {error && (
          <Box marginTop={1}>
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <Box marginTop={2} display="flex" justifyContent="flex-end">
        {!file.name ? (
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            startIcon={<CloudUpload />}
            component="label"
          >
            <input
              accept="text/plain"
              type="file"
              hidden
              onChange={handleUpload}
            />
            {t('enroll.uploadFile')}
          </Button>
        ) : (
          <Button
            startIcon={<Delete />}
            onClick={() => setFile({ dataURL: '', name: '' })}
          >
            {t('enroll.removeFile')}
          </Button>
        )}

        <Box marginRight={2} />
        <Button type="submit" color="secondary" disabled={loading}>
          {t('enroll.submit')}
          <Loading isLoading={loading} />
        </Button>
      </Box>
    </form>
  );
};

export default Enroll;
