import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Copyright from '../components/molecules/Copyright';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('routes.not-found')} | Facilittei</title>
      </Helmet>
      <Box
        height={1 / 2}
        width={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography variant="h4" gutterBottom>
          Página não encontrada
        </Typography>
        <Container maxWidth="sm">
          <Typography variant="subtitle1" align="center">
            A página que você está procurando não foi encontrada. Volte para a
            página anterior ou visite o{' '}
            <a href="https://facilittei.com/">Facilittei</a> para mais
            informações.
          </Typography>
        </Container>
        <Box paddingY={2}>
          <Button variant="outlined" color="primary" component="a" href="/">
            Voltar para sua home
          </Button>
        </Box>
        <Copyright />
      </Box>
    </>
  );
};

export default NotFound;
