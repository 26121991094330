import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Container, LinearProgress, Typography } from '@mui/material';

interface ProgressBoxProps {
  text?: string;
}

const ProgressBox = ({ text }: ProgressBoxProps) => (
  <Container maxWidth="xs">
    <Box paddingY={2}>
      <Typography align="center" variant="subtitle2">
        <Trans i18nKey={text} />
      </Typography>
    </Box>
    <LinearProgress />
  </Container>
);

export default ProgressBox;
