import { TFunction } from 'i18next';
import * as yup from 'yup';

export const newCourseSchema = (t: TFunction) =>
  yup.object().shape({
    price: yup.mixed().transform((val) => parseFloat(val.replace(',', '.'))),
    cover: yup.string(),
    title: yup.string().required(t('validations.title.required')),
    description: yup.string().required(t('validations.description.required')),
  });
