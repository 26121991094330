import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ContentToggle from '../molecules/ContentToggle';
import { LessonModel } from '../../models/lesson.model';
import NoContentImg from './../../assets/no-content.svg';
import CommentList from './CommentList';
import GridBox from '../atoms/GridBox';
import { Check, DoneAll, Favorite, FavoriteBorder } from '@mui/icons-material';
import LessonService from '../../services/lesson.service';
import RichTextBox from '../molecules/RichTextBox';
import MediaPlayer from '../atoms/MediaPlayer';
import { ViewMode } from '../../models/user.model';
import { CourseModel } from '../../models/course.model';

interface LessonViewProps {
  lesson: LessonModel;
  course?: CourseModel;
  viewOnly?: boolean;
  view?: ViewMode;
  updateLesson?: (state: { course: CourseModel; lesson: LessonModel }) => void;
}

const LessonView = ({
  lesson,
  course,
  viewOnly = false,
  view = ViewMode.Learning,
  updateLesson,
}: LessonViewProps) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const [content, setContent] = useState<string>('video');
  const [state, setState] = useState<[boolean, boolean]>([false, false]);

  useEffect(
    () => setState([!!lesson.favorited, !!lesson.watched]),
    [lesson.favorited, lesson.watched]
  );

  const [favorited, watched] = state;

  const getContent = () => {
    let iframeSrc;
    if (content === 'video') {
      iframeSrc = lesson.video;
    }

    if (content === 'audio') {
      iframeSrc = lesson.audio;
    }

    if (content === 'doc' && lesson.doc) {
      return (
        <Box>
          <Typography align="center">
            <Trans i18nKey="attachments" />
          </Typography>
          <Box marginBottom={2} />
          <a href={lesson.doc} rel="noreferrer" target="_blank">
            {lesson.doc}
          </a>
        </Box>
      );
    }

    if (!iframeSrc) {
      return (
        <>
          <img src={NoContentImg} height={200} />
          <Box marginBottom={2} />
          <Typography>
            <Trans i18nKey={`noContent.${content}`} />
          </Typography>
        </>
      );
    }

    return <MediaPlayer src={iframeSrc as string} />;
  };

  const handleFavorited = () => {
    setState([!favorited, state[1]]);
    LessonService.favoriteLesson(lesson.chapter.id, lesson.id).catch(() =>
      setState([!favorited, state[1]])
    );
  };

  const handleWatched = () => {
    setState([state[0], !watched]);
    if (course && updateLesson && course.watched) {
      if (course.watched.length) {
        // eslint-disable-next-line max-len
        const watchedIdx = course.watched.findIndex((e) => e === lesson.id);
        !watched
          ? course.watched.push(lesson.id)
          : course.watched.splice(watchedIdx, 1);
      }
      updateLesson({ course, lesson });
    }
    LessonService.watchedLesson(lesson.chapter.id, lesson.id).catch(() =>
      setState([state[0], !watched])
    );
  };

  return (
    <Card>
      <Box
        width={1}
        height={xs ? 300 : 425}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {getContent()}
      </Box>
      <Divider />
      <GridBox flexWrap={xs ? 'wrap-reverse' : 'initial'}>
        <Box marginTop={xs ? 1 : 2} marginBottom={2}>
          <ContentToggle content={content} onChange={setContent} />
        </Box>
        {!viewOnly && view === ViewMode.Learning && (
          <Box color={watched ? 'success.main' : 'action.active'}>
            <IconButton
              style={{ paddingLeft: xs ? 0 : 12 }}
              onClick={handleFavorited}
              size="large"
            >
              {favorited ? <Favorite color="error" /> : <FavoriteBorder />}
            </IconButton>
            <Button
              color="inherit"
              endIcon={watched ? <DoneAll color="inherit" /> : <Check />}
              onClick={handleWatched}
            >
              <Box color="action.active">
                <Trans i18nKey={watched ? 'watched' : 'markWatched'} />
              </Box>
            </Button>
          </Box>
        )}
      </GridBox>
      <Typography variant="h6">{lesson.title}</Typography>
      <Typography variant="caption">
        {moment(lesson.updatedAt).format('LL')}
      </Typography>
      <RichTextBox content={lesson.description} />
      {!viewOnly && <CommentList lessonId={lesson.id} />}
    </Card>
  );
};

export default LessonView;
