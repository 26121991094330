import { ChapterModel } from '../models/chapter.model';
import { CourseModel } from '../models/course.model';
import { handleAPIError } from '../utils/error-handling';
import http from '../utils/http';

const newChapter = async (
  courseId: number,
  chapter: ChapterModel
): Promise<ChapterModel> => {
  try {
    const { data } = await http.post('/chapters', {
      ...chapter,
      course_id: courseId,
      isPublished: true,
    });
    return data.chapter;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getChaptersUnauth = async (courseId: number): Promise<CourseModel> => {
  try {
    const { data } = await http.get(`/courses/${courseId}/view`);

    return {
      chapters: [],
      ...data,
    };
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getChapters = async (courseId: number): Promise<CourseModel> => {
  try {
    const { data } = await http.get(`/courses/${courseId}/chapters`);

    return {
      chapters: [],
      ...data,
    };
  } catch (error) {
    throw handleAPIError(error);
  }
};

const deleteChapter = async (chapter: ChapterModel) => {
  try {
    const { data } = await http.delete(`/chapters/${chapter.id}`, {
      data: {
        course_id: chapter.courseId,
      },
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const editChapter = async (chapter: ChapterModel) => {
  try {
    const { data } = await http.put(`/chapters/${chapter.id}`, chapter);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const reorderChapters = async (
  courseId: number,
  chapters: number[]
): Promise<ChapterModel[]> => {
  try {
    const { data } = await http.patch(`/courses/${courseId}/chapters/reorder`, {
      chapters,
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const ChapterService = {
  newChapter,
  getChapters,
  deleteChapter,
  editChapter,
  reorderChapters,
  getChaptersUnauth,
};

export default ChapterService;
