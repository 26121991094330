import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface OutlinedCardProps {
  children: React.ReactNode;
}

const OutlinedCard = ({ children }: OutlinedCardProps) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  const padding = matchesSm ? 2 : 6;

  return (
    <Box
      height={1}
      display="flex"
      alignItems={matchesSm ? 'flex-start' : 'center'}
      justifyContent="center"
    >
      <Box
        marginTop={matchesSm ? 2 : 0}
        width={1}
        maxWidth={theme.breakpoints.values.sm}
      >
        <Paper variant="outlined">
          <Box
            paddingX={padding}
            paddingY={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {children}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OutlinedCard;
