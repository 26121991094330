import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Close, Publish, Image } from '@mui/icons-material';

interface UploadImageProps {
  defaultImg?: string;
  onChange: (imageSrc: string) => void;
}

const UploadImage = ({ defaultImg, onChange }: UploadImageProps) => {
  const [imageSrc, setImageSrc] = useState(defaultImg || '');

  const setImagePreview = (input: React.ChangeEvent<HTMLInputElement>) => {
    if (input.target.files && input.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(input.target.files[0]);
      reader.onload = (e) => {
        if (e.target) {
          const img = e.target.result as string;
          setImageSrc(img);
          onChange(img);
        }
      };
    }
  };

  const reset = () => {
    setImageSrc('');
    onChange('');
  };

  return (
    <Box display="flex" marginTop={2} justifyContent="center" height={1}>
      <Box textAlign="center" color="error.main">
        <Box
          width={145}
          height={145}
          borderRadius="16px"
          bgcolor="background.default"
          fontSize="5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              style={{
                maxWidth: '100%',
                height: '100%',
                borderRadius: '16px',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Image fontSize="inherit" color="disabled" />
          )}
        </Box>
        <Box paddingY={1 / 2} />
        {imageSrc ? (
          <Button color="inherit" startIcon={<Close />} onClick={reset}>
            <Trans i18nKey="editCourse.removeCover" />
          </Button>
        ) : (
          <Button color="secondary" startIcon={<Publish />} component="label">
            <input
              accept="image/*"
              type="file"
              hidden
              onChange={setImagePreview}
            />
            <Trans i18nKey="editCourse.uploadCover" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UploadImage;
