import React from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';

interface MediaPlayerProps {
  src: string;
}

const MediaPlayer = ({ src }: MediaPlayerProps) => {
  return (
    <Box position="relative" paddingTop="56.25%" width="100%">
      <ReactPlayer
        controls
        className="react-player"
        height="100%"
        width="100%"
        url={src}
      />
    </Box>
  );
};

export default MediaPlayer;
