import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { cardDetailsSchema } from '../schemas/cardDetailsSchema';

export const useOrder = () => {
  const { t } = useTranslation();

  const cardDetailsForm = useForm({
    resolver: yupResolver(cardDetailsSchema(t)),
  });

  return { cardDetailsForm };
};
