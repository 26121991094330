import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { DoneAll, RadioButtonUnchecked } from '@mui/icons-material';
import { LessonModel } from '../../models/lesson.model';
import GridBox from '../atoms/GridBox';

interface LessonListProps {
  lessons: LessonModel[];
  watched?: number[];
  current?: number;
}

const LessonList = ({ lessons, watched, current }: LessonListProps) => {
  return (
    <Box paddingY={1}>
      {lessons?.map((lesson) => (
        <ButtonBase
          key={lesson.id}
          style={{ width: '100%' }}
          href={`/chapter/${lesson.chapterId}/lesson/${lesson.id}`}
        >
          <GridBox alignItems="flex-start" paddingY={1}>
            <Box display="flex">
              {watched?.includes(lesson.id) ? (
                <Box color="success.main">
                  <DoneAll color="inherit" fontSize="small" />
                </Box>
              ) : (
                <RadioButtonUnchecked color="action" fontSize="small" />
              )}
              <Box marginRight={1} />
              <Typography variant="subtitle2">
                {current == lesson.id ? (
                  <b>{lesson.title}</b>
                ) : (
                  <>{lesson.title}</>
                )}
              </Typography>
            </Box>
          </GridBox>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default LessonList;
