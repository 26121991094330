import React from 'react';
import { Trans } from 'react-i18next';
import { DataGrid, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PersonAddOutlined, RemoveCircleOutlined } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';

import ListHeader from '../molecules/ListHeader';
import { UserModel } from '../../models/user.model';
import { CourseModel } from '../../models/course.model';
import GridBox from '../atoms/GridBox';

interface StudentsGridProps {
  courses: CourseModel[];
  students: UserModel[] | undefined;
  selectedCourse: CourseModel;
  onChange: SelectProps['onChange'];
  onEnroll: () => void;
  onDelete: () => void;
  onSelection: (ids: number[]) => void;
  selectedIds?: number[];
}

interface CustomToolbarProps {
  onChange: SelectProps['onChange'];
  onEnroll: () => void;
  onDelete: () => void;
  selected: number;
  courses: CourseModel[];
  disabled?: boolean;
}

const columns: GridColDef[] = [
  {
    field: 'name',
    renderHeader() {
      return (
        <b>
          <Trans i18nKey="myStudents.name" />
        </b>
      );
    },
    flex: 1,
  },
  {
    field: 'email',
    renderHeader() {
      return (
        <b>
          <Trans i18nKey="myStudents.email" />
        </b>
      );
    },
    flex: 1,
  },
];

const CustomToolbar = (props: CustomToolbarProps) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GridBox padding={2} flexWrap={xs ? 'wrap' : 'initial'}>
      <Box minWidth={120} maxWidth={280}>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="select-course-label">
            <Trans i18nKey="myStudents.courses" />
          </InputLabel>
          <Select
            autoWidth
            defaultValue={props.selected}
            value={props.selected}
            onChange={props.onChange}
            labelId="select-course-label"
            id="select-course"
            label={<Trans i18nKey="myStudents.courses" />}
          >
            {props.courses.map((course) => (
              <MenuItem key={course.id} value={course.id}>
                {course.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex">
        <Button
          disabled={props.disabled}
          startIcon={
            <RemoveCircleOutlined
              color={props.disabled ? 'disabled' : 'error'}
            />
          }
          onClick={props.onDelete}
        >
          <Trans i18nKey="myStudents.annul" />
        </Button>
        <Box marginRight={2} />
        <Button startIcon={<PersonAddOutlined />} onClick={props.onEnroll}>
          <Trans i18nKey="myStudents.enroll" />
        </Button>
      </Box>
    </GridBox>
  );
};

const CustomPagination = (props: { selectedCount: number }) => {
  const apiRef = useGridApiContext();
  const state = apiRef.current.state;

  return (
    <GridBox paddingX={2} paddingY={1}>
      <Box color="text.hint">
        <Box hidden={!props.selectedCount}>
          <Trans i18nKey="selectedRows" count={props.selectedCount} />
        </Box>
      </Box>
      <Pagination
        color="primary"
        disabled={state.rows.totalRowCount < 11}
        page={state.pagination.page + 1}
        count={state.pagination.pageCount}
        onChange={(_, value) => apiRef.current.setPage(value - 1)}
      />
    </GridBox>
  );
};

const StudentsGrid = ({
  courses,
  students,
  selectedCourse,
  onChange,
  onEnroll,
  onDelete,
  onSelection,
  selectedIds = [],
}: StudentsGridProps) => {
  return (
    <>
      <ListHeader title="myStudents.title" />
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        checkboxSelection
        loading={!students}
        onSelectionModelChange={(selectionModel) =>
          onSelection(selectionModel as number[])
        }
        components={{
          Toolbar() {
            return (
              <CustomToolbar
                onChange={onChange}
                onEnroll={onEnroll}
                onDelete={onDelete}
                selected={selectedCourse.id}
                courses={courses}
                disabled={selectedIds.length === 0}
              />
            );
          },
          Footer() {
            return <CustomPagination selectedCount={selectedIds.length} />;
          },
          NoRowsOverlay() {
            return (
              <Box
                position="absolute"
                top="60%"
                left={0}
                right={0}
                textAlign="center"
              >
                <Trans i18nKey="myStudents.empty" />
              </Box>
            );
          },
          ColumnResizeIcon() {
            return <span />;
          },
        }}
        pageSize={10}
        columns={columns}
        rows={students || []}
      />
    </>
  );
};

export default StudentsGrid;
