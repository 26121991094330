import React from 'react';
import { Trans } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BgSpace from '../../assets/bg-space.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      backgroundImage: `url(${BgSpace})`,
      backgroundSize: 'contain',
      borderRadius: 16,
    },
    button: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      margin: 'auto',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.white,
      },
    },
  })
);

const FindMoreCard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box
      className={classes.root}
      width={xsScreen ? 144 : 240}
      height={145}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box padding={2}>
        <Typography variant="subtitle2" align={xsScreen ? 'center' : 'left'}>
          <b>
            <Trans
              i18nKey={xsScreen ? 'findMore.titleShort' : 'findMore.title'}
            />
          </b>
        </Typography>
        <Box marginTop={2} textAlign="center">
          <Button
            disableElevation
            className={classes.button}
            variant="contained"
            href="https://facilittei.com"
            target="_blank"
          >
            <Trans i18nKey="findMore.action" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindMoreCard;
