import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import Loading from '../atoms/Loading';
import UploadImage from '../molecules/UploadImage';
import TextInput from '../atoms/TextInput';
import { profileSchema } from '../../schemas/profile.schema';
import { ProfileModel } from '../../models/profile.model';

interface ProfileFormProps {
  t: TFunction;
  profile: ProfileModel;
  loading?: boolean;
  error?: string;
  onSubmit: (data: ProfileModel) => void;
  onCancel: () => void;
}

const ProfileForm = ({
  t,
  loading,
  error,
  profile,
  onSubmit,
  onCancel,
}: ProfileFormProps) => {
  const { control, register, handleSubmit, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(profileSchema),
  });

  useEffect(() => {
    profile.photo && setValue('photo', profile.photo);
  }, [profile.photo, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm="auto">
          <Box marginTop={2}>
            <UploadImage
              defaultImg={profile.photo}
              onChange={(imageSrc) => setValue('photo', imageSrc)}
            />
            <input type="hidden" {...register('photo')} />
          </Box>
        </Grid>
        <Grid item xs={12} sm>
          <TextInput
            id="name"
            label={t('user.name')}
            name="name"
            control={control}
            defaultValue={profile.name}
          />
          <TextInput
            multiline
            rows={4}
            id="bio"
            label={t('profile.bio')}
            name="bio"
            control={control}
            defaultValue={profile.bio}
          />
          <Box textAlign="right" marginTop={2}>
            <Button onClick={onCancel}>{t('editCourse.cancel')}</Button>
            <Box marginRight={2} display="inline" />
            <Button color="primary" type="submit" disabled={loading}>
              {t('editCourse.submit')}
              <Loading isLoading={loading} />
            </Button>
          </Box>
        </Grid>
      </Grid>
      {error && (
        <Box marginTop={1}>
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        </Box>
      )}
    </form>
  );
};

export default ProfileForm;
