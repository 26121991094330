import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

interface InsightCardProps {
  title: string;
  value?: number;
  loading?: boolean;
}

const InsightCard = ({ title, value, loading }: InsightCardProps) => {
  const dimensions = {
    minWidth: 112,
    minHeight: 113,
  };

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        width={dimensions.minWidth + 32}
        height={dimensions.minHeight + 32}
      />
    );
  }

  return (
    <Card>
      <Box
        {...dimensions}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h3">{value || 0}</Typography>
      </Box>
    </Card>
  );
};

export default InsightCard;
