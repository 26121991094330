import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import ProgressBox from '../components/atoms/ProgressBox';
import { CourseModel } from '../models/course.model';
import { LessonModel } from '../models/lesson.model';
import { trackPageView } from '../utils/GA/analytics';

const LessonRoutes = () => {
  const { chapterId, id } = useParams() as { chapterId: string; id: string };
  const location = useLocation();

  const { data: lesson, isLoading: isLoadingLesson } = useQuery<LessonModel>(
    [`/chapters/${chapterId}/lessons/${id}`],
    {
      select: (data) => ({
        ...data.current,
        favorited: data.favorited,
        watched: data.watched,
      }),
    }
  );

  const { isLoading: isLoadingCourse } = useQuery<CourseModel>(
    [`/courses/${+(lesson?.chapter?.course?.id || '')}/chapters`],
    {
      enabled: !!lesson?.id,
    }
  );

  if (isLoadingLesson || isLoadingCourse) {
    return <ProgressBox text="myCourses.loading" />;
  }

  const title = `${lesson?.title} | Facilittei`;
  trackPageView({
    page_title: title,
    page_path: location.pathname,
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default LessonRoutes;
