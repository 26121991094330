import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface RoundProgressProps {
  value: number;
}

const RoundProgress = ({ value }: RoundProgressProps) => {
  return (
    <Box position="relative" display="inline-flex">
      <Box position="absolute" color="grey.200">
        <CircularProgress
          color="inherit"
          variant="determinate"
          value={100}
          thickness={4}
        />
      </Box>
      <CircularProgress variant="determinate" thickness={4} value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default RoundProgress;
