import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt-br';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'pt',
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    moment.locale('pt-br');
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
