import React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { CourseModel } from '../models/course.model';
import ProgressBox from '../components/atoms/ProgressBox';
import CourseViewHead from '../components/organisms/CourseViewHead';
import AccordionList from '../components/molecules/AccordionList';
import { LessonModel } from '../models/lesson.model';
import { useNotification } from '../providers/NotificationProvider';
import ModalBox from '../components/molecules/ModalBox';
import CourseViewInfo from '../components/organisms/CourseViewInfo';
import { useAuth } from '../providers/AuthProvider';
import PreviewLessonList from '../components/organisms/PreviewLessonList';
import LessonList from '../components/organisms/LessonList';
import LessonPreview from '../components/organisms/LessonPreview';
import CoursePriceCard from '../components/organisms/CoursePriceCard';

const Course = () => {
  const { signedUser } = useAuth();
  const { id } = useParams() as { id: string };
  const { render, close } = useNotification();

  const { data: course, isLoading } = useQuery<CourseModel>(
    [`/courses/${id}/${signedUser ? 'chapters' : 'view'}`],
  );

  if (!course) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <ProgressBox text="course.loading" />;
  }

  const openLessonPreview = async (lesson: LessonModel) => {
    render(
      <ModalBox open onClose={close} title={lesson.title}>
        <LessonPreview lessonId={lesson.id} chapterId={lesson.chapterId} />
      </ModalBox>
    );
  };

  const getAccordionData = () => {
    return course?.chapters.map((chapter) => ({
      title: chapter.title,
      content: !course.hasAccess ? (
        <PreviewLessonList
          onOpenPreview={openLessonPreview}
          lessons={chapter.lessons}
        />
      ) : (
        <LessonList lessons={chapter.lessons} watched={course.watched} />
      ),
    }));
  };

  return (
    <Box paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item sm md>
          <CourseViewHead course={course} />
          <Box mt={2} />
          <CourseViewInfo course={course} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          {!course.hasAccess && (
            <Box mb={2}>
              <CoursePriceCard course={course} />
            </Box>
          )}
          <Card className="no-padding">
            <Box paddingX={2}>
              <AccordionList active={0} data={getAccordionData()} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Course;
