import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

export interface DragDropBoxProps {
  onReorder: (result: DropResult, provided: ResponderProvided) => void;
  children: ReactNode[];
}

const DragDropBox = ({ onReorder, children }: DragDropBoxProps) => {
  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children.map((el, index) => (
              <Draggable
                key={`${index}`}
                draggableId={`${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {el}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropBox;
