import React, { MouseEvent } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AttachFile, Audiotrack, Videocam } from '@mui/icons-material';

interface ContentToggleProps {
  content: string;
  onChange: (selected: string) => void;
}

const ContentToggle = ({ content, onChange }: ContentToggleProps) => {
  const handleChange = (_: MouseEvent, selected: string) => {
    onChange(selected);
  };

  return (
    <>
      <ToggleButtonGroup
        exclusive
        size="small"
        value={content}
        onChange={handleChange}
        aria-label="text alignment"
      >
        <ToggleButton value="video">
          <Videocam fontSize="small" />
        </ToggleButton>
        <ToggleButton value="audio">
          <Audiotrack fontSize="small" />
        </ToggleButton>
        <ToggleButton value="doc">
          <AttachFile fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default ContentToggle;
