import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Fab } from '@mui/material';
import { Add } from '@mui/icons-material';

interface FloatingButtonProps {
  label?: string;
  onClick?: () => void;
}

const FloatingButton = ({ label, onClick }: FloatingButtonProps) => (
  <Box position="fixed" bottom={16} right={16}>
    <Fab variant="extended" size="medium" color="secondary" onClick={onClick}>
      <Add />
      <Box marginLeft={1}>
        <Trans i18nKey={label} defaultValue={label} />
      </Box>
    </Fab>
  </Box>
);

export default FloatingButton;
