import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Avatar, Box, Card, Divider, Grid, Typography } from '@mui/material';
import {
  CastForEducationOutlined,
  Devices,
  LockOpen,
  PlayArrow,
} from '@mui/icons-material';

import GridBox from '../atoms/GridBox';
import { useCourseSummary } from './hooks/useCourseSummary';

const CheckoutSummaryCard = () => {
  const { courseId } = useParams() as { courseId: string };
  const { course } = useCourseSummary(+courseId);

  const highlights = [
    {
      icon: <CastForEducationOutlined color="action" />,
      message: `${course?.lessons} aulas disponíveis`,
    },
    {
      icon: <LockOpen color="action" />,
      message: 'Acesso sempre que desejar',
    },
    {
      icon: <Devices color="action" />,
      message: 'Aprenda em qualquer lugar',
    },
  ];

  return (
    <Box marginY={2}>
      <Card>
        <GridBox justifyContent="flex-start">
          <Avatar variant="rounded" src={course?.cover} className="small">
            <PlayArrow />
          </Avatar>
          <Box paddingLeft={2}>
            <Typography variant="h6">{course?.title}</Typography>
            <Typography variant="subtitle1">{course?.profile?.name}</Typography>
          </Box>
        </GridBox>
        <Box marginBottom={2} />
        <Divider />
        <Grid container>
          {highlights?.map((item, idx) => (
            <Grid item xs={12} sm={6} key={idx}>
              <GridBox justifyContent="flex-start" paddingTop={2}>
                {item.icon}
                <Box marginRight={1} />
                <Typography variant="subtitle1">{item.message}</Typography>
              </GridBox>
            </Grid>
          ))}
        </Grid>
        <Box marginY={2}>
          <Divider />
        </Box>
        <GridBox>
          <Typography variant="h5">Total</Typography>
          <Typography variant="h5">
            <Trans
              i18nKey="currency"
              values={{ price: course?.price?.toString().replace('.', ',') }}
            />
          </Typography>
        </GridBox>
      </Card>
    </Box>
  );
};

export default CheckoutSummaryCard;
