import React from 'react';
import { Box, Button } from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import { Trans } from 'react-i18next';

const NavHeader = (props: { goBack: () => void }) => {
  return (
    <Box marginBottom={1}>
      <Button startIcon={<NavigateBefore />} onClick={props.goBack}>
        <Trans i18nKey="back" />
      </Button>
    </Box>
  );
};

export default NavHeader;
