import {
  AuthEvent,
  AuthMethod,
  Config,
  ItemEvent,
  PageViewEvent,
  PurchaseEvent,
} from './gtag.types';

const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || '';

export const setAnalyticsConfig = (config: Config) => {
  gtag<Config>('config', measurementId, {
    debug_mode: process.env.NODE_ENV === 'development',
    send_page_view: false,
    ...config,
  });
};

export const trackPageView = (pageViewProps: PageViewEvent) => {
  gtag<PageViewEvent>('event', 'page_view', pageViewProps);
};

export const trackLogin = (
  authProps: AuthEvent = { method: AuthMethod.EmailAndPassword }
) => {
  gtag<AuthEvent>('event', 'login', authProps);
};

export const trackSignUp = (
  authProps: AuthEvent = { method: AuthMethod.EmailAndPassword }
) => {
  gtag<AuthEvent>('event', 'sign_up', authProps);
};

export const trackItemView = (itemProps: ItemEvent) => {
  gtag<ItemEvent>('event', 'view_item', itemProps);
};

export const trackBeginCheckout = (itemProps: ItemEvent) => {
  gtag<ItemEvent>('event', 'begin_checkout', itemProps);
};

export const trackPurchase = (purchaseProps: PurchaseEvent) => {
  gtag<ItemEvent>('event', 'purchase', purchaseProps);
};
