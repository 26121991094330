import React from 'react';
import { Avatar, Box, Card, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { CourseModel } from '../../models/course.model';
import GridBox from '../atoms/GridBox';
import RichTextBox from '../molecules/RichTextBox';

const CourseViewInfo = ({ course }: { course: CourseModel }) => {
  return (
    <Card>
      <Typography variant="h5">
        <Trans i18nKey="course.description" />
      </Typography>
      <RichTextBox content={course.description} />
      <Box mt={4} />
      <Typography variant="h5">
        <Trans i18nKey="course.instructor" />
      </Typography>
      <Box mt={1} />
      <GridBox justifyContent="flex-start" paddingTop={1}>
        <Avatar
          variant="rounded"
          src={course?.profile?.photo}
          className="large"
        >
          {course?.profile?.name[0]}
        </Avatar>
        <Box marginRight={2} />
        <Box>
          <Typography variant="body1">{course?.profile?.name}</Typography>
          <Typography variant="subtitle1">{course?.profile?.bio}</Typography>
        </Box>
      </GridBox>
    </Card>
  );
};

export default CourseViewInfo;
