import React from 'react';
import { Trans } from 'react-i18next';
import { MenuItem, MenuList } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: `${+theme.spacing * 2}px 0`,
    },
  })
);

interface CourseMenuProps {
  onEdit?: () => void;
  onViewStudents?: () => void;
}

const CourseMenu = ({ onEdit, onViewStudents }: CourseMenuProps) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.root}>
      <MenuItem onClick={onEdit}>
        <Trans i18nKey="editCourse.header" />
      </MenuItem>
      <MenuItem onClick={onViewStudents}>
        <Trans i18nKey="home.students" />
      </MenuItem>
    </MenuList>
  );
};

export default CourseMenu;
