import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Typography,
} from '@mui/material';
import { ArrowDropDown, Delete, ForumOutlined } from '@mui/icons-material';
import { CommentModel } from '../../models/comment.model';
import LessonService from '../../services/lesson.service';
import { useAuth } from '../../providers/AuthProvider';

interface CommentListProps {
  lessonId: number;
}

const CommentList = ({ lessonId }: CommentListProps) => {
  const commentForm = useForm({ mode: 'onChange' });
  const replyForm = useForm();
  const [showReplyInput, setShowReplyInput] = useState<number>();
  const [showReplies, setShowReplies] = useState<number>();
  const [comments, setComments] = useState<CommentModel[]>([]);
  const { signedUser } = useAuth();

  const fetchComments = () => {
    LessonService.getComments(lessonId).then(setComments);
  };

  useEffect(fetchComments, [lessonId]);

  const updateComment = async (comment: CommentModel) => {
    try {
      const newComment = await LessonService.newComment(lessonId, comment);

      if (newComment.parentId) {
        const idx = comments.findIndex((c) => c.id === newComment.parentId);
        comments[idx] = {
          ...comments[idx],
          user: signedUser,
          comments: [...(comments[idx]?.comments || []), newComment],
        };
        setShowReplies(newComment.parentId);
      } else {
        comments.unshift({
          ...newComment,
          user: signedUser,
        });
      }
      setComments(comments);
      commentForm.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (props: CommentModel) => {
    try {
      updateComment(props);
      commentForm.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitReply = (props: CommentModel) => {
    try {
      updateComment(props);
      replyForm.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteComment = async (id: number) => {
    try {
      await LessonService.deleteComment(lessonId, id);
      setComments([...comments.filter((c) => c.id !== id)]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReplyComment = (commentId: number | undefined) => () => {
    if (commentId) {
      if (commentId !== showReplyInput) {
        replyForm.reset();
      } else {
        replyForm.setFocus('description');
      }
    }

    setShowReplyInput(commentId);
  };

  return (
    <Box marginTop={2}>
      <Divider />
      <Box display="flex" height="76px" marginTop={2}>
        <Avatar src={signedUser?.profile?.photo}>{signedUser?.name[0]}</Avatar>
        <Box marginRight={1} />
        <form
          onSubmit={commentForm.handleSubmit(
            onSubmit as SubmitHandler<FieldValues>
          )}
        >
          <Box marginTop={0.5} />
          <Input
            {...commentForm.register('description', {
              required: true,
              setValueAs: (val) => val?.trim(),
            })}
            fullWidth
            multiline
            maxRows={4}
            size="small"
            id="description"
            placeholder="Escreva um comentário"
            inputProps={{ maxLength: 150 }}
          />
          <Box textAlign="right" marginTop={1}>
            <Button color="secondary" type="submit">
              <Trans i18nKey="comment" />
            </Button>
          </Box>
        </form>
      </Box>
      <Box display="flex">
        <ForumOutlined />
        <Box marginRight={1} />
        <Trans i18nKey="comments" count={comments.length} />
      </Box>
      {comments.map(({ comments: replies = [], ...comment }) => (
        <Box key={comment.id} display="flex" marginTop={2}>
          <Avatar src={comment?.user?.profile?.photo}>
            {comment?.user?.name[0]}
          </Avatar>
          <Box display="grid" marginLeft={1} flexGrow={1}>
            <Typography
              variant="caption"
              color={
                comment?.user?.id === signedUser?.id ? 'secondary' : 'initial'
              }
            >
              {comment?.user?.name}
              <Box display="inline" color="text.disabled">
                {` ${moment(comment.updatedAt).fromNow()}`}
              </Box>
            </Typography>
            <Typography variant="subtitle2">{comment.description}</Typography>
            <Box>
              <Box color="action.active">
                <Button
                  color="inherit"
                  size="small"
                  sx={{
                    padding: 0,
                    textTransform: 'uppercase',
                    fontWeight: 'unset',
                  }}
                  onClick={handleReplyComment(comment.id)}
                >
                  Responder
                </Button>
              </Box>
              {showReplyInput === comment.id && (
                <form
                  onSubmit={replyForm.handleSubmit(
                    // eslint-disable-next-line max-len
                    onSubmitReply as SubmitHandler<FieldValues>
                  )}
                >
                  <Box marginTop={0.5} />
                  <Input
                    {...replyForm.register('description', {
                      required: true,
                    })}
                    fullWidth
                    multiline
                    maxRows={4}
                    size="small"
                    id="description"
                    placeholder="Escreva sua resposta"
                    inputProps={{ maxLength: 150 }}
                    autoFocus
                    sx={{ fontSize: '14px' }}
                  />
                  <input
                    type="hidden"
                    {...replyForm.register('parentId', {
                      valueAsNumber: true,
                    })}
                    value={comment.id}
                  />
                  <Box color="action.active" textAlign="right" marginTop={1}>
                    <Button
                      color="inherit"
                      onClick={handleReplyComment(undefined)}
                    >
                      <Trans i18nKey="cancel" />
                    </Button>
                    <Button color="secondary" type="submit">
                      <Trans i18nKey="comment" />
                    </Button>
                  </Box>
                </form>
              )}
              {replies.length > 0 && (
                <Button
                  color="info"
                  size="small"
                  endIcon={<ArrowDropDown />}
                  sx={{
                    padding: 0,
                  }}
                  onClick={() =>
                    setShowReplies(
                      showReplies === comment.id ? undefined : comment.id
                    )
                  }
                >
                  <Trans
                    i18nKey={
                      showReplies === comment.id
                        ? 'Esconder respostas'
                        : 'Ver respostas'
                    }
                  />
                </Button>
              )}
            </Box>
            {showReplies === comment.id &&
              replies.map((reply) => (
                <Box key={reply.id} display="flex" paddingTop={1}>
                  <Avatar
                    src={reply?.user?.profile?.photo}
                    sx={{ width: 32, height: 32 }}
                  >
                    {reply?.user?.name[0]}
                  </Avatar>
                  <Box display="grid" marginLeft={1} flexGrow={1}>
                    <Typography
                      variant="caption"
                      color={
                        reply?.user?.id === signedUser?.id
                          ? 'secondary'
                          : 'initial'
                      }
                    >
                      {reply?.user?.name}
                      <Box display="inline" color="text.disabled">
                        {` ${moment(reply.updatedAt).fromNow()}`}
                      </Box>
                    </Typography>
                    <Typography variant="subtitle2">
                      {reply.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          {comment?.user?.id === signedUser?.id && (
            <IconButton
              size="small"
              onClick={() => handleDeleteComment(comment.id)}
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CommentList;
