import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Card, Typography } from '@mui/material';
import WelcomeImg from './../../assets/welcome.svg';

const WelcomeCard = ({ userName }: { userName?: string }) => {
  return (
    <Box width={1}>
      <Card>
        <Box
          position="relative"
          minHeight={113}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="h4">
            <Trans i18nKey="home.salutation" values={{ userName }} />
          </Typography>
          <Typography variant="h6">
            <Trans i18nKey="home.welcome" />
          </Typography>
          <Box position="absolute" right={0} bottom={-4}>
            <img alt="No Data" src={WelcomeImg} height={140} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default WelcomeCard;
