import React, { ReactNode } from 'react';
import { Box, Card, useTheme } from '@mui/material';

interface FlexibleCardProps {
  fullWidth?: boolean;
  center?: boolean;
  children?: ReactNode;
}

const FlexibleCard = ({ fullWidth, center, children }: FlexibleCardProps) => {
  const theme = useTheme();
  const boxProps = center
    ? {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }
    : {};

  return (
    <Box width={1} sx={boxProps}>
      <Box width={1} maxWidth={fullWidth ? 1 : theme.breakpoints.values.sm}>
        <Card>
          <Box sx={boxProps}>{children}</Box>
        </Card>
      </Box>
    </Box>
  );
};

export default FlexibleCard;
