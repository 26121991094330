import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface GridBoxProps {
  width?: string | number;
  height?: string | number;
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: number;
  paddingY?: number;
  paddingX?: number;
  paddingTop?: number;
  paddingBottom?: number;
  gutterBottom?: boolean;
  flexWrap?: string;
  children?: ReactNode;
}

const GridBox = ({
  width,
  height,
  justifyContent = 'space-between',
  gutterBottom,
  flexDirection,
  alignItems,
  children,
  ...props
}: GridBoxProps & BoxProps) => (
  <Box
    sx={{
      ...props,
      width: width || 1,
      maxWidth: 1,
      height: height || (flexDirection === 'column' ? 1 : 'intial'),
      marginBottom: gutterBottom ? 2 : 0,
      display: 'flex',
      flexDirection: flexDirection || 'row',
      justifyContent: flexDirection === 'column' ? 'center' : justifyContent,
      alignItems:
        alignItems || (flexDirection === 'column' ? 'flex-start' : 'center'),
    }}
  >
    {children}
  </Box>
);

export default GridBox;
