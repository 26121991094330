import { TFunction } from 'i18next';
import * as yup from 'yup';

export const newLessonSchema = (t: TFunction) =>
  yup.object().shape({
    title: yup.string().required(t('validations.title.required')),
    description: yup.string(),
    audio: yup
      .string()
      .url(t('validations.url.invalid'))
      .matches(/soundcloud\.com./, {
        excludeEmptyString: true,
        message: t('validations.url.soundcloud'),
      }),
    video: yup
      .string()
      .url(t('validations.url.invalid'))
      .matches(/https:\/\/www\.youtube\.com\/watch\?v=./, {
        excludeEmptyString: true,
        message: t('validations.url.youtube'),
      }),
    doc: yup.string().url(t('validations.url.invalid')),
    isPreview: yup.boolean().default(false),
  });
