import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import ProgressBox from '../components/atoms/ProgressBox';
import { CourseModel } from '../models/course.model';
import { useAuth } from '../providers/AuthProvider';
import { trackPageView } from '../utils/GA/analytics';

const CourseRoutes = () => {
  const { signedUser } = useAuth();
  const { id } = useParams() as { id: string };
  const location = useLocation();

  const { data: course, isLoading } = useQuery<CourseModel>(
    [`/courses/${id}/${signedUser ? 'chapters' : 'view'}`]
  );

  if (isLoading) {
    return <ProgressBox text="myCourses.loading" />;
  }

  const title = `${course?.title} | Facilittei`;
  trackPageView({ page_title: title, page_path: location.pathname });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default CourseRoutes;
