import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { setAnalyticsConfig, trackPageView } from '../utils/GA/analytics';

const GenericRoutes = () => {
  const { signedUser } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  setAnalyticsConfig(signedUser?.id ? { user_id: `${signedUser.id}` } : {});
  
  const pathname = location.pathname.split('/')[1];
  if (pathname === 'course' || pathname === 'chapter' || !pathname) {
    return <Outlet />;
  }

  const title = `${t(`routes.${pathname}`)} | Facilittei`;
  trackPageView({ page_title: title, page_path: location.pathname });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default GenericRoutes;
