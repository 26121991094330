import { TFunction } from 'i18next';
import * as yup from 'yup';

export const forgotPasswordSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t('validations.email.required'))
      .email(t('validations.email.invalid')),
  });

export const signInSchema = (t: TFunction) =>
  forgotPasswordSchema(t).concat(
    yup.object().shape({
      password: yup
        .string()
        .required(t('validations.password.required'))
        .min(8, t('validations.password.invalid')),
    })
  );

export const signUpSchema = (t: TFunction) =>
  signInSchema(t).concat(
    yup.object().shape({
      name: yup.string().required(t('validations.name.required')),
      terms: yup.boolean().oneOf([true], t('validations.terms.required')),
    })
  );

export const resetPasswordSchema = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('validations.password.required'))
      .min(8, t('validations.password.invalid')),
  });

export const inviteSchema = (t: TFunction) =>
  yup.object().shape({
    terms: yup.boolean().oneOf([true], t('validations.terms.required')),
    password: yup
      .string()
      .required(t('validations.password.required'))
      .min(8, t('validations.password.invalid')),
  });

export const changePasswordSchema = (t: TFunction) =>
  yup.object().shape({
    oldPassword: yup
      .string()
      .required(t('validations.password.required'))
      .min(8, t('validations.password.invalid')),
    password: yup
      .string()
      .required(t('validations.password.required'))
      .min(8, t('validations.password.invalid')),
  });
