import React, { ReactNode } from 'react';
import { Box, Button, Divider, Paper } from '@mui/material';
import {
  AccountCircleOutlined,
  CastForEducationOutlined,
  ExitToApp,
  ForumOutlined,
  LocalLibraryOutlined,
  NavigateNext,
  PeopleAltOutlined,
} from '@mui/icons-material';
import UserService from '../../services/user.service';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { Trans } from 'react-i18next';
import { MenuContentProps } from '../molecules/MenuPopover';

const AppMenu = ({ handleClose }: MenuContentProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const navTo = (path: string) => {
    navigate(`/${path}`);
    handleClose && handleClose();
  };

  return (
    <Paper>
      <Box color="text.secondary" maxWidth={260}>
        <MenuItem
          icon={<LocalLibraryOutlined />}
          label="home.myLearning"
          onClick={() => navTo(`my-learning`)}
        />
        <MenuItem
          icon={<CastForEducationOutlined />}
          label="home.myCourses"
          onClick={() => navTo(`my-courses`)}
        />
        <MenuItem
          icon={<PeopleAltOutlined />}
          label="home.myStudents"
          onClick={() => navTo(`my-students`)}
        />
        <MenuItem
          icon={<ForumOutlined />}
          label="home.comments"
          onClick={() => navTo(`comments`)}
        />
        <Box paddingY={1 / 2}>
          <Divider />
        </Box>
        <MenuItem
          icon={<AccountCircleOutlined />}
          label="home.account"
          noEndIcon
          onClick={() => navTo(`account`)}
        />
        <MenuItem
          noEndIcon
          icon={<ExitToApp />}
          label="home.leave"
          onClick={() => {
            UserService.logout().finally(() => {
              logout();
            });
          }}
        />
      </Box>
    </Paper>
  );
};

interface MenuItemProps {
  label: string;
  icon: ReactNode;
  noEndIcon?: boolean;
  onClick?: () => void;
}

const MenuItem = ({
  label,
  icon,
  noEndIcon = false,
  onClick,
}: MenuItemProps) => (
  <Button startIcon={icon} color="inherit" onClick={onClick}>
    <Box
      width={226}
      display="flex"
      justifyContent="space-between"
      color="text.secondary"
    >
      <Trans i18nKey={label} />
      {!noEndIcon && <NavigateNext color="inherit" />}
    </Box>
  </Button>
);

export default AppMenu;
