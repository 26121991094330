import React, { useEffect, useState, MouseEvent } from 'react';
import { Box, Chip, Divider, IconButton, Typography } from '@mui/material';
import { DragIndicator, Edit } from '@mui/icons-material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DropResult } from 'react-beautiful-dnd';
import AccordionList from '../components/molecules/AccordionList';
import { ChapterModel } from '../models/chapter.model';
import { LessonModel } from '../models/lesson.model';
import ListInput from '../components/atoms/ListInput';
import { CourseModel } from '../models/course.model';
import ChapterService from '../services/chapter.service';
import LessonService from '../services/lesson.service';
import { useSnackbar } from 'notistack';
import { useNotification } from '../providers/NotificationProvider';
import EditLesson from './EditLesson';
import SimpleDialog from '../components/atoms/SimpleDialog';
import DeleteButton from '../components/atoms/DeleteButton';
import ModalBox from '../components/molecules/ModalBox';
import ProgressBox from '../components/atoms/ProgressBox';
import useFeedback from '../states/FeedbackState';
import DragDropBox from '../components/atoms/DragDropBox';

interface NewChapterProps {
  course: CourseModel;
  onUpdate: (chapter: ChapterModel[]) => void;
}

const NewChapter = ({ course, onUpdate }: NewChapterProps) => {
  const { handleSubmit, reset, control } = useForm();
  const {
    handleSubmit: handleSubmitModal,
    control: controlModal,
    setValue: setValueModal,
  } = useForm();
  const [chapters, setChapters] = useState<ChapterModel[]>();
  const [editChapter, setEditChapter] = useState<ChapterModel>();
  const { render, close } = useNotification();
  const chaptersFeedback = useFeedback();

  const feedback = useFeedback();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const fetchContent = () => {
    ChapterService.getChapters(course.id)
      .then(({ chapters }) => {
        setChapters(chapters);
        onUpdate(chapters);
      })
      .catch(() => setChapters([]));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchContent, [course.id]);

  if (!chapters) {
    return <ProgressBox text="newChapter.loading" />;
  }

  const onSubmitChapter = async (newChapter: ChapterModel) => {
    if (!newChapter.title) {
      return;
    }

    try {
      chaptersFeedback.setFeedback(true);
      const chapter = await ChapterService.newChapter(course.id, newChapter);
      chapter.lessons = [];
      reset();
      setChapters([...chapters, chapter]);
      onUpdate([...chapters, chapter]);
      chaptersFeedback.setFeedback(false);
    } catch (err) {
      snackbar.enqueueSnackbar(t('newChapter.onNewChapterFail'));
      chaptersFeedback.setFeedback(false);
    }
  };

  const onEditChapter = async (chapter: ChapterModel) => {
    if (!chapter.title) {
      return;
    }

    try {
      if (editChapter) {
        chaptersFeedback.setFeedback(true);
        editChapter.title = chapter.title;
        await ChapterService.editChapter(editChapter);

        const idx = chapters.findIndex((c) => c.id === editChapter.id);
        chapters[idx] = editChapter;
        setChapters(chapters);
        setEditChapter(undefined);
      }
      chaptersFeedback.setFeedback(false);
    } catch (err) {
      snackbar.enqueueSnackbar(t('newChapter.onEditChapterFail'));
      chaptersFeedback.setFeedback(false);
    }
  };

  const handleEditChapter = (index: number) => (e: MouseEvent) => {
    e.stopPropagation();
    setValueModal('title', chapters[index]?.title);
    setEditChapter(chapters[index]);
  };

  const handleDeleteChapter = async () => {
    try {
      if (editChapter) {
        await ChapterService.deleteChapter(editChapter);
        setChapters([...chapters.filter((c) => c.id !== editChapter.id)]);
        setEditChapter(undefined);
        onUpdate(chapters.filter((c) => c.id !== editChapter.id));
        feedback.showSnackbar('newChapter.onDelete.success');
      }
    } catch (error) {
      feedback.showSnackbar('newChapter.onDelete.fail');
    }
  };

  const onLessonUpdate = (index: number) => (lessons: LessonModel[]) => {
    chapters[index].lessons = lessons;
    setChapters([...chapters]);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(chapters);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onReorder = async ({ source, destination }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    try {
      const reordered = reorder(source.index, destination.index);
      const positions = reordered.map((l) => l.id);
      setChapters(reordered);
      await ChapterService.reorderChapters(course.id, positions);
    } catch (err) {
      snackbar.enqueueSnackbar(t('newChapter.onNewLessonFail'));
    }
  };

  const accordionData = chapters.map((item, idx) => ({
    ...item,
    info: item?.lessons?.length
      ? t('newChapter.lessonsQtd', { count: item.lessons.length })
      : t('newChapter.empty'),
    content: (
      <NewLessonQuick
        key={item.id}
        chapterId={item.id}
        lessons={item.lessons}
        onUpdate={onLessonUpdate(idx)}
      />
    ),
  }));

  return (
    <Box width={1} marginTop={2}>
      <form
        onSubmit={handleSubmit(onSubmitChapter as SubmitHandler<FieldValues>)}
      >
        <Box paddingY={2} color="grey.600">
          <Typography variant="h5" color="textPrimary">
            {t('editCourse.courseStruct.title')}
          </Typography>
          <Typography variant="caption">
            {t('editCourse.courseStruct.description')}
          </Typography>
        </Box>
        <Divider />
        <ListInput
          placeholder={t('editCourse.newModule')}
          name="title"
          control={control}
          loading={chaptersFeedback.loading}
        />
        <Divider />
      </form>
      <AccordionList
        expandAll
        data={accordionData}
        active={chapters.length}
        onReorder={onReorder}
        actions={(index) => (
          <IconButton size="small" onClick={handleEditChapter(index)}>
            <Edit color="primary" fontSize="small" />
          </IconButton>
        )}
      />
      <ModalBox
        maxWidth="xs"
        open={!!editChapter}
        onClose={() => setEditChapter(undefined)}
        title="editCourse.editModule"
      >
        <form
          onSubmit={handleSubmitModal(
            onEditChapter as SubmitHandler<FieldValues>
          )}
        >
          {editChapter && (
            <ListInput
              placeholder={t('editCourse.newModule')}
              name="title"
              control={controlModal}
              defaultValue={editChapter?.title}
              loading={chaptersFeedback.loading}
            />
          )}
        </form>
        <Box textAlign="center">
          <DeleteButton
            onClick={() =>
              render(
                <SimpleDialog
                  title={t('editCourse.onChapterDelete.dialogTitle')}
                  content={t('editCourse.onChapterDelete.dialogContent')}
                  onCancel={close}
                  onContinue={() => {
                    handleDeleteChapter();
                    close();
                  }}
                />
              )
            }
          />
        </Box>
      </ModalBox>
    </Box>
  );
};

interface QuickLessonProps {
  chapterId: number;
  lessons: LessonModel[];
  onUpdate: (lessons: LessonModel[]) => void;
}

const NewLessonQuick = ({
  chapterId,
  lessons = [],
  onUpdate,
}: QuickLessonProps) => {
  const { handleSubmit, reset, control } = useForm();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const [openEditLesson, setOpenEditLesson] = useState<LessonModel>();
  const [list, setList] = useState(lessons);
  const lessonsFeedback = useFeedback();

  const onSubmitLesson = async (newLesson: LessonModel) => {
    if (!newLesson.title) {
      return;
    }

    try {
      lessonsFeedback.setFeedback(true);
      const lesson = await LessonService.newLesson(chapterId, newLesson);
      const updatedList = [...list, lesson];
      setList(updatedList);
      onUpdate(updatedList);
      reset();
      lessonsFeedback.setFeedback(false);
    } catch (error) {
      snackbar.enqueueSnackbar(t('newChapter.onNewLessonFail'));
    }
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onReorder = async ({ source, destination }: DropResult) => {
    if (!destination || source.index === destination.index) {
      return;
    }

    try {
      const reordered = reorder(source.index, destination.index);
      const positions = reordered.map((l) => l.id);
      setList(reordered);
      await LessonService.reorderLessons(chapterId, positions);
    } catch (err) {
      snackbar.enqueueSnackbar(t('newChapter.onNewLessonFail'));
    }
  };

  const handleUpdate = (lesson: LessonModel) => {
    const idx = list.findIndex((l) => l.id === lesson.id);
    list[idx] = lesson;
    setList([...list]);
  };

  const handleDelete = (lesson: LessonModel) => {
    const updatedList = list.filter((l) => l.id !== lesson.id);
    setList([...updatedList]);
    onUpdate(updatedList);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitLesson as SubmitHandler<FieldValues>)}>
      <DragDropBox onReorder={onReorder}>
        {list.map((lesson) => (
          <Box
            key={lesson.id}
            paddingY={1}
            paddingX={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid"
            borderColor="grey.100"
          >
            <DragIndicator color="disabled" />
            <Box
              paddingLeft={1}
              flexGrow={1}
              display="flex"
              alignItems="center"
            >
              {lesson.title}
              {lesson.isPreview && (
                <Box marginLeft={1}>
                  <Chip label={<Trans i18nKey="preview" />} size="small" />
                </Box>
              )}
            </Box>
            <IconButton size="small" onClick={() => setOpenEditLesson(lesson)}>
              <Edit color="primary" fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </DragDropBox>
      <Divider />
      <ListInput
        placeholder="Adicionar nova aula"
        name="title"
        control={control}
        loading={lessonsFeedback.loading}
      />
      <ModalBox
        open={!!openEditLesson}
        onClose={() => setOpenEditLesson(undefined)}
        title="lesson.header"
      >
        {openEditLesson && (
          <EditLesson
            lesson={openEditLesson}
            chapterId={chapterId}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onClose={() => setOpenEditLesson(undefined)}
          />
        )}
      </ModalBox>
    </form>
  );
};

export default NewChapter;
