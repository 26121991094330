const masks = {
  CPF: () => maskCPF,
  CEP: () => maskCEP,
  cardNumber: () => maskCardNumber,
  monthYear: () => maskMonthYear,
  currency: () => maskCurrency,
  numeric: () => maskNumeric,
};

export type InputMask =
  | 'CPF'
  | 'CEP'
  | 'cardNumber'
  | 'monthYear'
  | 'currency'
  | 'numeric';
type ApplyMaskArgs = {
  name: string;
  mask?: InputMask;
  setValue?: (name: string, maskedValue: string) => void;
};

const maskCPF = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

const maskCEP = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{5})(\d{1,3})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

const maskCardNumber = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})\d+?$/, '$1');

const maskMonthYear = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{1,4})/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');

const maskNumeric = (value: string) => value.replace(/\D/g, '');

const maskCurrency = (value: string) => {
  if (+value % 1 === 0) return `${+value},00`;
  if (value.length === 1) return `0,0${value}`;
  if (value.length >= 9) return value.substring(0, 8);

  const decimalValue = parseFloat(
    value.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1')
  ).toFixed(2);
  return decimalValue.toString().replace('.', ',');
};

export const applyMask = (
  value: string,
  { mask, name, setValue }: ApplyMaskArgs
) => {
  if (!mask || !setValue) return '';
  const maskFn = masks[mask]();
  const maskedValue = maskFn(value);
  setValue(name, maskedValue);
};
