import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import OutlinedCard from '../molecules/OutlinedCard';
import CheckoutSuccessImg from './../../assets/checkout-success.svg';
import CheckoutFailImg from './../../assets/checkout-fail.svg';

interface CheckoutStatusProps {
  reset: () => void;
  isOrderSuccessful: boolean;
}

const CheckoutStatus = ({ reset, isOrderSuccessful }: CheckoutStatusProps) => {
  const navigate = useNavigate();
  const { courseId } = useParams() as { courseId: string };

  const goToCourse = () => {
    navigate(`/course/${courseId}`);
  };

  return (
    <OutlinedCard>
      <Typography variant="h5" gutterBottom>
        <Trans i18nKey="checkoutConfirmation.header" />
      </Typography>
      <Box paddingY={2}>
        <img
          height={140}
          alt="Status de pagamento"
          src={isOrderSuccessful ? CheckoutSuccessImg : CheckoutFailImg}
        />
      </Box>
      <Typography variant="h6" align="center">
        <Trans
          i18nKey={`checkoutConfirmation.message.${
            isOrderSuccessful ? 'success' : 'fail'
          }`}
        />
      </Typography>
      <Box marginY={2} />
      <Box textAlign="center" display="flex">
        {!isOrderSuccessful && (
          <Box marginRight={2} color="text.secondary">
            <Button
              variant="text"
              color="inherit"
              onClick={() => navigate('/')}
            >
              <Trans i18nKey="checkoutConfirmation.cancel" />
            </Button>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={isOrderSuccessful ? goToCourse : reset}
        >
          <Trans
            i18nKey={`checkoutConfirmation.${
              isOrderSuccessful ? 'goToCourse' : 'tryAgain'
            }`}
          />
        </Button>
      </Box>
    </OutlinedCard>
  );
};

export default CheckoutStatus;
