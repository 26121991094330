import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

import CourseService from '../services/course.service';
import { CourseModel } from '../models/course.model';
import { useNotification } from '../providers/NotificationProvider';
import CourseForm from '../components/organisms/CourseForm';

const NewCourse = ({ navigate }: { navigate: NavigateFunction }) => {
  const { t } = useTranslation();
  const { close } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (data: CourseModel) => {
    try {
      setLoading(true);
      setError('');
      const course = await CourseService.newCourse(data);
      data.cover &&
        (await CourseService.setCourseCover({
          ...course,
          ...data,
        }));
      navigate(`/edit-course/${course.id}`);
      close();
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CourseForm
      t={t}
      error={error}
      loading={loading}
      onCancel={close}
      onSubmit={onSubmit}
      course={{ cover: '' }}
    />
  );
};

export default NewCourse;
