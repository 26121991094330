import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import UserService from '../services/user.service';
import OutlinedCard from '../components/molecules/OutlinedCard';
import Logo from '../assets/full-logo-dark.svg';
import { setNewUserEmail } from '../utils/preferences-storage';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { code } = useParams() as { code: string };
  const [success, setSuccess] = useState<boolean>();

  const verifyEmail = () => {
    UserService.verifyEmail(code)
      .then((email) => {
        setNewUserEmail(email);
        setSuccess(true);
      })
      .catch(() => setSuccess(false));
  };

  useEffect(verifyEmail, [code]);

  const handleSubmit = () => {
    if (success) {
      navigate('/');
    } else {
      verifyEmail();
    }
  };

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      {success === undefined ? (
        <Box marginTop={1} width="100px">
          <LinearProgress />
          <Box marginTop={2} />
          <Typography align="center" variant="subtitle2">
            {t('verify.progress')}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            {t('verify.header')}
          </Typography>
          <Typography variant="body1" align="center">
            {t(`verify.message.${success ? 'success' : 'fail'}`)}
          </Typography>
          <Box marginY={2} />
          <Button
            variant={success ? 'contained' : 'text'}
            color="primary"
            onClick={handleSubmit}
          >
            {t(`verify.${success ? 'signIn' : 'tryAgain'}`)}
          </Button>
        </>
      )}
    </OutlinedCard>
  );
};

export default VerifyEmail;
