import React, { ReactElement, ReactNode, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Trans } from 'react-i18next';

interface TabsContainerProps {
  tabs: {
    label: string;
    icon: ReactElement;
  }[];
  children: ReactNode[];
}

const TabsContainer = ({ tabs, children }: TabsContainerProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            icon={tab.icon}
            iconPosition="start"
            label={<Trans i18nKey={tab.label} />}
          />
        ))}
      </Tabs>
      {children.map((child, index) => (
        <Box key={index} hidden={activeTab !== index} marginTop={2}>
          {child}
        </Box>
      ))}
    </>
  );
};

export default TabsContainer;
