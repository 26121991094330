import React from 'react';
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MUIRichTextEditor from 'mui-rte';
import { EditorState, convertToRaw } from 'draft-js';
import {
  UseFormRegister,
  FieldValues,
  FormState,
  UseFormSetValue,
} from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

interface RichTextInputProps {
  id?: string;
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  formState: FormState<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  readOnly?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: '4px',
      minHeight: '250px',
      borderColor: (props) =>
        (props as { error: boolean }).error
          ? theme.palette.error.main
          : theme.palette.grey[400],
    },
    toolbar: {
      '& .MuiIconButton-colorPrimary': {
        color: theme.palette.primary.main,
      },
    },
    editor: {
      padding: '0 14px',
    },
    placeHolder: {
      padding: '0 14px',
    },
    error: {
      borderBottom: 0,
      color: theme.palette.error.main,
    },
  })
);

const RichTextInput = ({
  name,
  errors,
  defaultValue,
  register,
  formState,
  setValue,
  ...props
}: RichTextInputProps) => {
  const errorMessage = errors && name ? errors[name]?.message : '';
  const classes = useStyles({ error: !!errorMessage });

  const handleChange = (state: EditorState) => {
    const content = state.getCurrentContent();
    setValue(
      name,
      content.hasText() ? JSON.stringify(convertToRaw(content)) : '',
      { shouldValidate: formState.isSubmitted }
    );
  };

  return (
    <Box>
      <MUIRichTextEditor
        {...props}
        classes={classes}
        onChange={handleChange}
        error={!!errorMessage}
        defaultValue={defaultValue || ''}
        controls={[
          'bold',
          'italic',
          'underline',
          'link',
          'numberList',
          'bulletList',
          'quote',
          'clear',
        ]}
      />
      <input type="hidden" {...props} {...register(name)} />
      <ErrorMessage error={errorMessage} />
    </Box>
  );
};

export default RichTextInput;
