import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import {
  CastForEducationOutlined,
  LocalLibraryOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WelcomeCard from '../components/organisms/WelcomeCard';
import InsightCard from '../components/molecules/InsightCard';
import CourseList from '../components/organisms/CourseList';
import DraftList from '../components/organisms/DraftList';
import FloatingButton from '../components/atoms/FloatingButton';
import HomeService from '../services/home.service';
import { HomeModel } from '../models/home.model';
import { useNotification } from '../providers/NotificationProvider';
import NewCourse from './NewCourse';
import { useAuth } from '../providers/AuthProvider';
import ModalBox from '../components/molecules/ModalBox';
import FindMoreCard from '../components/molecules/FindMoreCard';
import GetStartedCard from '../components/organisms/GetStartedCard';
import Empty from '../components/molecules/Empty';
import CourseService from '../services/course.service';
import { CourseModel } from '../models/course.model';
import ListHeader from '../components/molecules/ListHeader';
import LatestWatchedCard from '../components/organisms/LatestWatchedCard';
import { ViewMode } from '../models/user.model';

const Home = () => {
  const { t } = useTranslation();
  const { render, close } = useNotification();
  const { signedUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const [state, setState] = useState<HomeModel>();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(ViewMode.Learning);
  const [tabs, setTabs] = useState([
    {
      icon: <LocalLibraryOutlined />,
      label: 'Aprendendo',
      value: ViewMode.Learning,
    },
    {
      icon: <CastForEducationOutlined />,
      label: 'Ensinando',
      value: ViewMode.Teaching,
    },
  ]);

  const handleChange = (_: React.SyntheticEvent, tabValue: ViewMode) => {
    setTab(tabValue);
  };

  const setStats = (courses: CourseModel[] = []) => {
    return CourseService.getCourseStats().then((stats) => {
      Object.entries(stats).forEach(([courseId, progress]) => {
        const idx = courses.findIndex(({ id }) => id == +courseId);
        if (courses[idx]) {
          courses[idx].progress = progress;
        }
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    HomeService.getHomeInfo(2)
      .then(async (data) => {
        if (data.teaching?.courses?.length || data.teaching?.drafts?.length) {
          setTabs((prev) => prev.reverse());
          setTab(ViewMode.Teaching);
        }
        if (data.learning?.courses?.length) {
          await setStats(data.learning?.courses);
        }
        setState(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const openNewCourse = () => {
    render(
      <ModalBox open={true} onClose={close} title="newCourse.header">
        <NewCourse navigate={navigate} />
      </ModalBox>
    );
  };

  const EmptyComponent = () => (
    <>
      {tab === ViewMode.Teaching && !state?.teaching?.courses?.length && (
        <GetStartedCard onClick={openNewCourse} />
      )}
      {tab === ViewMode.Learning && !state?.learning?.courses?.length && (
        <Empty />
      )}
    </>
  );

  return (
    <>
      <Tabs value={tab} onChange={handleChange} sx={{ visibility: loading ? 'hidden' : 'visible' }}>
        {tabs.map((tabProps) => (
          <Tab key={tabProps.label} {...tabProps} iconPosition="start" />
        ))}
      </Tabs>
      <Box marginTop={4}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={12} sm>
              <WelcomeCard userName={signedUser?.name} />
            </Grid>
            {tab === ViewMode.Learning && (
              <Grid item xs sm="auto">
                <Box
                  paddingLeft={xsScreen ? 0 : 2}
                  paddingRight={xsScreen ? 2 : 0}
                  paddingTop={xsScreen ? 2 : 0}
                >
                  <FindMoreCard />
                </Box>
              </Grid>
            )}
            <Grid item xs sm="auto">
              <Box paddingLeft={xsScreen ? 0 : 2} paddingTop={xsScreen ? 2 : 0}>
                <InsightCard
                  title={t('home.courses')}
                  value={
                    tab === ViewMode.Teaching
                      ? state?.teaching?.stats?.courses
                      : state?.learning?.stats?.courses
                  }
                  loading={loading}
                />
              </Box>
            </Grid>
            {tab === ViewMode.Teaching && (
              <Grid item xs sm="auto">
                <Box paddingLeft={2} paddingTop={xsScreen ? 2 : 0}>
                  <InsightCard
                    title={t('home.myStudents')}
                    value={state?.teaching?.stats?.students}
                    loading={loading}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CourseList
              loading={loading}
              onSeeAll={() => navigate(`/my-courses`)}
              onViewCourse={(course) => navigate(`/course/${course?.id}/${course?.slug}`)}
              courses={
                tab === ViewMode.Teaching
                  ? state?.teaching?.courses
                  : state?.learning?.courses
              }
              view={tab}
              emptyComponent={<EmptyComponent />}
            />
          </Grid>
          {tab === ViewMode.Teaching &&
            state?.teaching &&
            state?.teaching?.drafts?.length > 0 && (
              <Grid item xs={12} md={6}>
                <DraftList
                  onSeeAll={() => navigate(`/my-courses`)}
                  onEditDraft={(id) => navigate(`/edit-course/${id}`)}
                  courses={state.teaching.drafts}
                />
              </Grid>
            )}
          {tab === ViewMode.Learning && state?.learning?.latestWatched && (
            <Grid item xs={12} md={6}>
              <ListHeader
                title="home.latestWatched.header"
                buttonLabel="home.latestWatched.view"
                onClick={() => {
                  const lw = state?.learning?.latestWatched;
                  navigate(`/course/${lw?.course.id}`);
                }}
              />
              <LatestWatchedCard
                latestWatched={state?.learning?.latestWatched}
                onResume={() => {
                  const lw = state?.learning?.latestWatched;
                  navigate(
                    `/chapter/${lw?.chapters.id}/lesson/${lw?.lessons.id}`
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
        {tab === ViewMode.Teaching && (
          <FloatingButton label="home.fab" onClick={openNewCourse} />
        )}
      </Box>
    </>
  );
};

export default Home;
