import { TFunction } from 'i18next';
import * as yup from 'yup';

export const cardDetailsSchema = (t: TFunction) =>
  yup.object().shape({
    cardNumber: yup.string().required(t('validations.cardNumber.required')),
    securityCode: yup.string().required(t('validations.securityCode.required')),
    expirationDate: yup
      .string()
      .required(t('validations.expirationDate.required')),
  });
