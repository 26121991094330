import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

import GridBox from '../atoms/GridBox';

interface ListHeaderProps {
  title?: string;
  buttonLabel?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const ListHeader = ({
  title,
  buttonLabel,
  onClick,
  disabled,
}: ListHeaderProps) => {
  return (
    <GridBox paddingY={2}>
      <Typography variant="h5">
        <Trans i18nKey={title} defaultValue={title} />
      </Typography>
      {onClick && (
        <Button
          color="secondary"
          endIcon={<NavigateNext fontSize="small" />}
          onClick={onClick}
          disabled={disabled}
        >
          <Trans i18nKey={buttonLabel} defaultValue={buttonLabel} />
        </Button>
      )}
    </GridBox>
  );
};

export default ListHeader;
