import React from 'react';
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ProfileModel } from '../../models/profile.model';
import {
  AccountCircleOutlined,
  Face,
  Lock,
  NavigateNext,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';
import moment from 'moment';
import GridBox from '../atoms/GridBox';
import FlexibleCard from '../atoms/FlexibleCard';
import { UserModel } from '../../models/user.model';

interface ProfileMenuProps {
  user?: UserModel;
  profile: ProfileModel;
  onEdit: () => void;
  onChangePassword: () => void;
}

const ProfileMenu = ({
  user,
  profile,
  onEdit,
  onChangePassword,
}: ProfileMenuProps) => {
  return (
    <FlexibleCard center>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Avatar variant="rounded" src={profile.photo} className="profile">
            <Face />
          </Avatar>
        </Grid>
        <Grid item xs>
          <GridBox flexDirection="column">
            <Typography variant="h5">{user?.name}</Typography>
            <Typography variant="subtitle2">{user?.email}</Typography>
            <Typography variant="subtitle1">
              <Trans
                i18nKey="profile.createdAt"
                values={{ createdAt: moment(user?.createdAt).format('LL') }}
              />
            </Typography>
          </GridBox>
        </Grid>
      </Grid>
      <List>
        <ListItem button onClick={onEdit}>
          <ListItemIcon>
            <AccountCircleOutlined />
          </ListItemIcon>
          <ListItemText primary={<Trans i18nKey="profile.edit" />} />
          <NavigateNext />
        </ListItem>
        <ListItem button onClick={onChangePassword}>
          <ListItemIcon>
            <Lock />
          </ListItemIcon>
          <ListItemText primary={<Trans i18nKey="profile.changePassword" />} />
          <NavigateNext />
        </ListItem>
      </List>
    </FlexibleCard>
  );
};

export default ProfileMenu;
