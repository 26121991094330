import { AxiosError } from 'axios';
import { CourseModel } from '../models/course.model';
import { EnrollModel } from '../models/enroll.model';
import { HomeModel } from '../models/home.model';
import { LessonModel } from '../models/lesson.model';
import { InviteStatus, UserModel } from '../models/user.model';
import { handleAPIError } from '../utils/error-handling';
import { formDataFromBase64 } from '../utils/file';
import http from '../utils/http';

const newCourse = async ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cover: _,
  ...course
}: CourseModel): Promise<CourseModel> => {
  try {
    const { data } = await http.post('/courses', course);
    return data.course;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const editCourse = async (
  id: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { cover, ...course }: Partial<CourseModel>
): Promise<CourseModel> => {
  try {
    const { data } = await http.put(`/courses/${id}`, course);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getCourses = async (): Promise<CourseModel[]> => {
  try {
    const { data } = await http.get<HomeModel>(`/home`);
    return data?.teaching?.courses || [];
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getCourseById = async (id: number): Promise<CourseModel> => {
  try {
    const { data } = await http.get(`/courses/${id}`);
    return data;
  } catch (error) {
    throw (error as AxiosError).response?.status === 401
      ? 401
      : handleAPIError(error);
  }
};

const getCourseInfo = async (id: number): Promise<CourseModel> => {
  try {
    const { data } = await http.get(`/courses/${id}/info`);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getCourseStats = async (): Promise<Record<string, number>> => {
  try {
    const { data } = await http.get('courses/enrolled/stats');
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getAllFavorites = async (): Promise<LessonModel[]> => {
  try {
    const { data } = await http.get('courses/favorites');
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const deleteCourse = async (id: number): Promise<void> => {
  try {
    await http.delete(`/courses/${id}`);
  } catch (error) {
    throw handleAPIError(error);
  }
};

const setCourseCover = async ({ id, cover = '' }: CourseModel) => {
  try {
    const formData = await formDataFromBase64('cover', cover);
    await http.post(`/courses/${id}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getStudents = async (courseId: number): Promise<UserModel[]> => {
  try {
    const { data: students = [] } = await http.get<UserModel[]>(
      `/courses/${courseId}/students`
    );
    const { data: invited = [] } = await http.get<UserModel[]>(
      `/courses/${courseId}/invites`
    );
    return [
      ...students.map((s) => ({ ...s, status: InviteStatus.ALREADY_USER })),
      ...invited.map((i) => ({ ...i, status: InviteStatus.NEW_USER })),
    ];
  } catch (error) {
    throw handleAPIError(error);
  }
};

const enroll = async (courseId: number, enroll: EnrollModel) => {
  try {
    const { data } = await http.post(`/courses/${courseId}/enroll`, enroll);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const enrollMany = async (courseId: number, base64: string) => {
  try {
    const formData = await formDataFromBase64('attach', base64);
    await http.post(`/courses/${courseId}/enroll-many`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw handleAPIError(error);
  }
};

const annulMany = async (courseId: number, enrolled: UserModel[]) => {
  const users = enrolled
    .filter((s) => s.status === InviteStatus.ALREADY_USER)
    .map((s) => s.id);
  const invited = enrolled
    .filter((s) => s.status === InviteStatus.NEW_USER)
    .map((s) => s.id);

  users?.length && (await annulManyUsers(courseId, users));
  invited?.length && (await annulManyInvites(courseId, invited));
};

const annulManyUsers = async (courseId: number, studentsIds: number[]) => {
  try {
    const { data } = await http.delete(`/courses/${courseId}/annul-many`, {
      data: {
        users_id: studentsIds,
      },
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const annulManyInvites = async (courseId: number, studentsIds: number[]) => {
  try {
    const { data } = await http.delete(`/courses/${courseId}/annul-invites`, {
      data: {
        users_id: studentsIds,
      },
    });
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const CourseService = {
  newCourse,
  getCourseStats,
  editCourse,
  getCourses,
  getCourseById,
  deleteCourse,
  setCourseCover,
  enroll,
  enrollMany,
  annulMany,
  getStudents,
  getAllFavorites,
  getCourseInfo,
};

export default CourseService;
