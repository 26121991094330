interface Redirect {
  from: string;
  to: string;
}

interface Preferences {
  redirects?: Redirect[];
  newUserEmail?: string;
}

const getPreferences = (): Preferences => {
  const preferencesString = localStorage.getItem('preferences');
  if (!preferencesString) {
    return {};
  }

  try {
    return JSON.parse(preferencesString);
  } catch (error) {
    return {};
  }
};

const savePreferences = (preferences: Preferences) => {
  localStorage.setItem('preferences', JSON.stringify(preferences));
};

export const deletePreference = (key: keyof Preferences) => {
  const preferences = getPreferences();
  preferences[key] && delete preferences[key];
  savePreferences(preferences);
};

export const setRedirect = (redirect: Redirect) => {
  let preferences = getPreferences();
  const redirects = preferences.redirects || [];
  redirects.push(redirect);
  preferences = { ...preferences, redirects };
  savePreferences(preferences);
};

export const getRedirect = (pathname: string) => {
  const preferences = getPreferences();
  const redirect = preferences.redirects?.find((r) => r.from === pathname);
  return redirect?.to;
};

export const setNewUserEmail = (newUserEmail: string) => {
  let preferences = getPreferences();
  preferences = { ...preferences, newUserEmail };
  savePreferences(preferences);
};

export const getNewUserEmail = (): string => {
  const preferences = getPreferences();
  return preferences.newUserEmail || '';
};
