import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Dialog, Divider, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import GridBox from '../atoms/GridBox';

interface ModalBoxProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  maxWidth?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  children?: ReactNode;
}

const ModalBox = ({
  children,
  maxWidth = 'md',
  title,
  ...props
}: ModalBoxProps) => {
  return (
    <Dialog {...props} fullWidth maxWidth={maxWidth}>
      <GridBox paddingBottom={2}>
        <Typography variant="h5">
          <Trans i18nKey={title} />
        </Typography>
        <IconButton size="small" onClick={props.onClose}>
          <Close />
        </IconButton>
      </GridBox>
      <Divider />
      {children}
    </Dialog>
  );
};

export default ModalBox;
