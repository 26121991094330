import React, { useState } from 'react';
import {
  CardCvcElementComponent,
  CardExpiryElementComponent,
  CardNumberElementComponent,
} from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { Box, InputLabel } from '@mui/material';
import { ErrorOption } from 'react-hook-form';

const elementOptions = {
  placeholder: '',
  showIcon: true,
  style: {
    base: {
      fontFamily: 'Lexend',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    invalid: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
};

interface StripeInputProps {
  label?: string;
  element:
    | CardNumberElementComponent
    | CardExpiryElementComponent
    | CardCvcElementComponent;
  onComplete: () => void;
  onError: (error?: ErrorOption) => void;
}

const StripeInput = ({
  label,
  element: Element,
  onComplete,
  onError,
}: StripeInputProps) => {
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);
  const handleValidation = (event: StripeElementChangeEvent) => {
    if (!!event.error !== error) {
      setError(!!event.error);
    }

    if (event.complete) {
      onComplete();
    }

    onError(event.error);
  };

  return (
    <Box
      sx={{
        padding: '16.5px 14px',
        borderStyle: 'solid',
        borderWidth: focus ? '2px' : '1px',
        borderColor: error
          ? '#d32f2f'
          : focus
          ? '#8A05BE'
          : 'rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        position: 'relative',
        height: '56px',
      }}
    >
      <InputLabel
        sx={{
          position: 'absolute',
          top: 0,
          left: '-8px',
          transform: 'translate(14px, -9px) scale(0.75)',
          transformOrigin: 'top left',
          zIndex: 1,
          padding: '0 8px',
          backgroundColor: 'white',
          color: error ? '#d32f2f' : focus ? '#8A05BE' : '#3c4756',
        }}
      >
        {label}
      </InputLabel>
      <Element
        options={elementOptions}
        onChange={handleValidation}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </Box>
  );
};

export default StripeInput;
