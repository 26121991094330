import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserGroups, UserModel } from '../models/user.model';

interface AuthContextProps {
  signedUser: UserModel | undefined;
  setUser: (token: UserModel) => void;
  logout: () => void;
  hasPermission: (group: UserGroups) => boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [signedUser, setSignedUser] = useState<UserModel>();
  const setUser = (user: UserModel) => {
    localStorage.setItem('user', JSON.stringify(user));
    setSignedUser(user);
  };
  const logout = () => {
    localStorage.removeItem('user');
    setSignedUser(undefined);
  };
  const hasPermission = (group: UserGroups) => {
    return !!signedUser?.groups?.find((g) => g === group);
  };
  const contextValue = { signedUser, setUser, logout, hasPermission };

  useEffect(() => {
    const userStr = localStorage.getItem('user');
    const user = userStr ? JSON.parse(userStr) : undefined;
    setSignedUser(user);
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
