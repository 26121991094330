import React, { useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { Trans } from 'react-i18next';
import { convertFromRaw } from 'draft-js';
import { Box, ButtonBase, Collapse, Divider, Typography } from '@mui/material';

interface RichTextBoxProps {
  content?: string;
}

const RichTextBox = ({ content = '' }: RichTextBoxProps) => {
  const [showMore, setShowMore] = useState(false);
  const contentState = convertFromRaw(
    content
      ? JSON.parse(content)
      : {
          blocks: [],
          entityMap: {},
        }
  );

  const handleChange = () => {
    setShowMore(!showMore);
  };

  return (
    <Box width={1} position="relative">
      <Collapse
        in={showMore}
        collapsedSize={contentState?.getPlainText().length > 220 ? 76 : 32}
      >
        <MUIRichTextEditor
          readOnly
          controls={[]}
          defaultValue={content || ''}
        />
      </Collapse>
      {contentState.getPlainText().length > 160 && (
        <>
          <Box marginBottom={1}>
            <Divider />
          </Box>
          <ButtonBase disableRipple onClick={handleChange}>
            <Typography variant="overline" color="secondary">
              <Trans i18nKey={!showMore ? 'showMore' : 'showLess'} />
            </Typography>
          </ButtonBase>
        </>
      )}
    </Box>
  );
};

export default RichTextBox;
