import React from 'react';

import { CourseModel } from '../../models/course.model';
import ListHeader from '../molecules/ListHeader';
import DraftCard from './DraftCard';

interface DraftListProps {
  courses?: CourseModel[];
  onSeeAll?: () => void;
  onEditDraft: (id: number) => void;
}

const DraftList = ({ onSeeAll, onEditDraft, courses }: DraftListProps) => {
  return (
    <>
      <ListHeader
        title="home.draft.header"
        buttonLabel="home.viewAllCourses"
        onClick={onSeeAll}
      />
      {courses?.map((course) => (
        <DraftCard
          key={course.id}
          course={course}
          onClick={() => onEditDraft(course.id)}
        />
      ))}
    </>
  );
};

export default DraftList;
