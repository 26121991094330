import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import './utils/i18n';

import defaultTheme from './styles/app.style';
import AppRouter from './routers/AppRouter';
import { AuthProvider } from './providers/AuthProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import QueryProvider from './providers/QueryProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AuthProvider>
        <NotificationProvider>
          <Suspense fallback={false}>
            <QueryProvider>
              <AppRouter />
            </QueryProvider>
          </Suspense>
        </NotificationProvider>
      </AuthProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);
