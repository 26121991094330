import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Input } from '@mui/material';
import { Add } from '@mui/icons-material';
import { UseFormReturn, Controller } from 'react-hook-form';
import ContainedButton from './ContainedButton';

interface ListInputProps {
  defaultValue?: string;
  placeholder: string;
  name: string;
  control: UseFormReturn['control'];
  loading?: boolean;
}

const ListInput = ({
  control,
  name,
  defaultValue,
  loading = false,
  ...props
}: ListInputProps) => {
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (loading) {
      setDisableSave(true);
    }
  }, [loading]);

  const handleSaveVisibility = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (disableSave && value) {
      setDisableSave(false);
    }

    if (!disableSave && !value) {
      setDisableSave(true);
    }
  };

  return (
    <Box display="flex" width={1} paddingY={1}>
      {!defaultValue && <Box marginLeft={2} />}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field: { value, onChange } }) => (
          <Input
            {...props}
            fullWidth
            disableUnderline
            value={value}
            autoFocus={!!defaultValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              handleSaveVisibility(e);
            }}
            startAdornment={
              <Box paddingRight={1} display="flex">
                {!defaultValue && <Add color="disabled" />}
              </Box>
            }
          />
        )}
      />

      <ContainedButton
        title={defaultValue ? 'save' : 'add'}
        loading={loading}
        fullWidth={false}
        disabled={disableSave}
      />
    </Box>
  );
};

export default ListInput;
