import { useMutation } from '@tanstack/react-query';
import { client } from '../../../utils/client';
import { Order } from '../types/order';

export const useCheckout = () => {
  const resource = '/checkouts';
  const { mutateAsync, isSuccess, isError, isLoading, reset } = useMutation(
    (order: Order) => client.post(resource, order)
  );

  return {
    processOrder: mutateAsync,
    isOrderSubmitting: isLoading,
    isOrderSubmitted: isSuccess || isError,
    isOrderSuccessful: isSuccess,
    reset,
  };
};
