import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import VerticalStepper from '../navigation/VerticalStepper';
import CardDetailsForm from './CardDetailsForm';
import CheckoutTerms from './CheckoutTerms';
import CheckoutSummaryCard from './CheckoutSummaryCard';
import ProgressBox from '../atoms/ProgressBox';
import CheckoutStatus from './CheckoutStatus';
import { useCourseSummary } from './hooks/useCourseSummary';
import { useOrder } from './hooks/useOrder';
import { useCheckout } from './hooks/useCheckout';
import StripeProvider from '../../providers/StripeProvider';
import { Order, OrderResult } from './types/order';
import { trackBeginCheckout, trackPurchase } from '../../utils/GA/analytics';

const Checkout = () => {
  const navigate = useNavigate();
  const { courseId } = useParams() as { courseId: string };
  const { course, isFetchingCourse } = useCourseSummary(+courseId);
  const { cardDetailsForm } = useOrder();
  const {
    isOrderSubmitted,
    isOrderSubmitting,
    isOrderSuccessful,
    processOrder,
    reset,
  } = useCheckout();
  
  const [order, setOrder] = useState<Order>({ courseId: +courseId });
  const queryClient = useQueryClient();

  useEffect(() => {
    trackBeginCheckout({
      currency: 'BRL',
      value: +(course?.price || '0'),
      items: [
        {
          item_id: courseId,
          item_name: course?.title,
          affiliation: course?.profile?.name,
        },
      ],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!course) {
    navigate('/not-found');
    return null;
  }

  const handlePaymentDetails =
    (goToNextStep: () => void) => async (creditCard?: string) => {
      setOrder({ ...order, creditCard });
      goToNextStep();
    };

  const handleOrder = async () => {
    const result = await processOrder(order);
    await queryClient.invalidateQueries([`/courses/${courseId}/chapters`]);
    const { order_id, fees } = result.data as OrderResult;
    trackPurchase({
      currency: 'BRL',
      value: +(course?.price || '0'),
      transaction_id: order_id,
      tax: fees,
      items: [
        {
          item_id: courseId,
          item_name: course?.title,
        },
      ],
    });
  };

  if (isFetchingCourse || isOrderSubmitting) {
    return <ProgressBox text="loading" />;
  }

  if (isOrderSubmitted) {
    return (
      <CheckoutStatus reset={reset} isOrderSuccessful={isOrderSuccessful} />
    );
  }

  return (
    <>
      <CheckoutSummaryCard />
      <VerticalStepper
        steps={[
          {
            label: <Trans i18nKey="cardDetailsForm.header" />,
            content: function getContent({ goToPreviousStep, goToNextStep }) {
              return (
                <StripeProvider>
                  <CardDetailsForm
                    formMethods={cardDetailsForm}
                    onBack={goToPreviousStep}
                    onSubmit={handlePaymentDetails(goToNextStep)}
                  />
                </StripeProvider>
              );
            },
            completed: cardDetailsForm.formState.isSubmitSuccessful,
          },
          {
            label: <Trans i18nKey="checkoutTerms.header" />,
            content: function getContent({ goToPreviousStep }) {
              return (
                <CheckoutTerms
                  onBack={goToPreviousStep}
                  onConfirm={handleOrder}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default Checkout;
