import React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { LessonModel } from '../../models/lesson.model';
import { newLessonSchema } from '../../schemas/lesson.schema';
import TextInput from '../atoms/TextInput';
import RichTextInput from '../atoms/RichTextInput';
import { Audiotrack, Description, Videocam } from '@mui/icons-material';
import ErrorMessage from '../atoms/ErrorMessage';
import GridBox from '../atoms/GridBox';
import DeleteButton from '../atoms/DeleteButton';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import Loading from '../atoms/Loading';

interface LessonFormProps {
  t: TFunction;
  loading?: boolean;
  lesson: LessonModel;
  error?: string;
  onSubmit: (data: LessonModel) => void;
  onDelete: () => void;
}

const LessonForm = ({
  t,
  lesson,
  onDelete,
  onSubmit,
  loading,
  error,
}: LessonFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(newLessonSchema(t)),
  });
  register('video');

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Box marginY={2}>
        <TextInput
          id="title"
          label={t<string>('title')}
          name="title"
          control={control}
          defaultValue={lesson.title}
        />
        <RichTextInput
          id="description"
          label={t('course.description')}
          name="description"
          register={register}
          formState={formState}
          setValue={setValue}
          errors={errors}
          defaultValue={lesson.description}
        />
        <TextInput
          id="video"
          label={t<string>('url.video')}
          name="video"
          control={control}
          defaultValue={lesson.video}
          startIcon={<Videocam color="disabled" />}
        />
        <TextInput
          id="audio"
          label={t<string>('url.audio')}
          name="audio"
          control={control}
          defaultValue={lesson.audio}
          startIcon={<Audiotrack color="disabled" />}
        />
        <TextInput
          id="doc"
          label={t<string>('url.doc')}
          name="doc"
          control={control}
          defaultValue={lesson.doc}
          startIcon={<Description color="disabled" />}
        />
        <Box textAlign="right" mt={2}>
          <Typography variant="subtitle1">
            {t<string>('lesson.preview')}
          </Typography>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Controller
                control={control}
                name="isPreview"
                defaultValue={lesson.isPreview}
                render={({ field }) => <Switch {...field} color="primary" />}
              />
            }
            label=""
          />
        </Box>
        <ErrorMessage error={error} />
      </Box>
      <GridBox>
        <DeleteButton onClick={onDelete} />
        <Button type="submit" color="secondary" disabled={loading}>
          <>
            {t('lesson.submit')}
            <Loading isLoading={loading} />
          </>
        </Button>
      </GridBox>
    </form>
  );
};

export default LessonForm;
