import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Link, Box } from '@mui/material';

import Logo from '../assets/full-logo-dark.svg';
import Copyright from '../components/molecules/Copyright';
import { forgotPasswordSchema } from '../schemas/user.schema';
import UserService from '../services/user.service';
import OutlinedCard from '../components/molecules/OutlinedCard';
import ContainedButton from '../components/atoms/ContainedButton';
import TextInput from '../components/atoms/TextInput';
import useFeedback from '../states/FeedbackState';
import ErrorMessage from '../components/atoms/ErrorMessage';

const ForgotPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema(t)),
  });
  const navigate = useNavigate();
  const { loading, error, setFeedback, showSnackbar } = useFeedback();

  const onSubmit = async (data: unknown) => {
    try {
      setFeedback(true);
      await UserService.forgotPassword(data);
      showSnackbar('forgotPassword.success');
      navigate('/');
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <OutlinedCard>
      <Box paddingBottom={1}>
        <img alt="logo" src={Logo} height={40} />
      </Box>
      <Typography variant="h5" gutterBottom>
        {t('forgotPassword.header')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          autoFocus
          id="email"
          label={t('user.email')}
          name="email"
          control={control}
        />
        <ErrorMessage error={error} />
        <Box paddingY={2} width={1}>
          <ContainedButton title="forgotPassword.submit" loading={loading} />
        </Box>
        <Box textAlign="right">
          <Link variant="body2" component={RouterLink} to="/" underline="hover">
            {t('forgotPassword.signInHref')}
          </Link>
        </Box>
      </form>
      <Box mt={8}>
        <Copyright />
      </Box>
    </OutlinedCard>
  );
};

export default ForgotPassword;
