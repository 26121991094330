import { ChapterModel } from '../models/chapter.model';
import { CommentModel } from '../models/comment.model';
import { LessonModel } from '../models/lesson.model';
import { handleAPIError } from '../utils/error-handling';
import http from '../utils/http';

const newLesson = async (
  chapterId: number,
  lesson: LessonModel
): Promise<LessonModel> => {
  try {
    const { data } = await http.post(`/chapters/${chapterId}/lessons`, {
      isPublished: true,
      ...lesson,
    });
    return data.lesson;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const updateLesson = async (
  chapterId: number,
  lesson: LessonModel
): Promise<LessonModel> => {
  try {
    const { data } = await http.put(
      `/chapters/${chapterId}/lessons/${lesson.id}`,
      lesson
    );
    return data.lesson;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getLesson = async (
  chapterId: number,
  id: number
): Promise<LessonModel> => {
  try {
    const { data } = await http.get(`/chapters/${chapterId}/lessons/${id}`);
    return {
      ...data.current,
      favorited: data.favorited,
      watched: data.watched,
    };
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getLessonInfo = async (
  chapterId: number,
  id: number
): Promise<LessonModel> => {
  try {
    const { data } = await http.get(
      `/chapters/${chapterId}/lessons/${id}/view`
    );
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getLessons = async (chapterId: number): Promise<ChapterModel> => {
  try {
    const { data } = await http.get(`/chapters/${chapterId}/lessons`);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getComments = async (lessonId: number): Promise<CommentModel[]> => {
  try {
    const { data } = await http.get(`/lessons/${lessonId}/comments`);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const deleteComment = async (lessonId: number, id: number): Promise<void> => {
  try {
    const { data } = await http.delete(`/lessons/${lessonId}/comments/${id}`);
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const newComment = async (
  lessonId: number,
  comment: CommentModel
): Promise<CommentModel> => {
  try {
    const { data } = await http.post(`/lessons/${lessonId}/comments`, comment);
    return data.comment;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const reorderLessons = async (
  chapterId: number,
  lessons: number[]
): Promise<LessonModel> => {
  try {
    const { data } = await http.patch(
      `/chapters/${chapterId}/lessons/reorder`,
      { lessons }
    );
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const favoriteLesson = async (chapterId: number, lessonId: number) => {
  try {
    const { data } = await http.post(
      `/chapters/${chapterId}/lessons/${lessonId}/favorited`
    );
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const watchedLesson = async (chapterId: number, lessonId: number) => {
  try {
    const { data } = await http.post(
      `/chapters/${chapterId}/lessons/${lessonId}/watched`
    );
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const deleteLesson = async (lesson: LessonModel) => {
  try {
    const { data } = await http.delete(
      `/chapters/${lesson.chapterId}/lessons/${lesson.id}`
    );
    return data;
  } catch (error) {
    throw handleAPIError(error);
  }
};

const getAllComments = async () => {
  try {
    const { data } = await http.get(`/comments`);
    return data?.comments || [];
  } catch (error) {
    throw handleAPIError(error);
  }
};

const uploadVideo = async ({ chapterId, id }: LessonModel, video: File) => {
  try {
    const formData = new FormData();
    formData.append('video', video);
    await http.post(`/chapters/${chapterId}/lessons/${id}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw handleAPIError(error);
  }
};

const LessonService = {
  newLesson,
  getLesson,
  getLessonInfo,
  getLessons,
  updateLesson,
  reorderLessons,
  getComments,
  newComment,
  favoriteLesson,
  watchedLesson,
  deleteLesson,
  deleteComment,
  getAllComments,
  uploadVideo,
};

export default LessonService;
