import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ProfileModel } from '../models/profile.model';
import UserService from '../services/user.service';
import ProfileMenu from '../components/organisms/ProfileMenu';
import { useAuth } from '../providers/AuthProvider';
import ProfileForm from '../components/organisms/ProfileForm';
import ChangePasswordForm from '../components/organisms/ChangePasswordForm';
import ModalBox from '../components/molecules/ModalBox';
import useFeedback from '../states/FeedbackState';

const Profile = () => {
  const { t } = useTranslation();
  const { signedUser, setUser } = useAuth();
  const { loading, error, setFeedback } = useFeedback();
  const [openDialog, setOpenDialog] = useState<number>();
  const [profile, setProfile] = useState<ProfileModel | undefined>(
    signedUser?.profile
  );

  const fetchProfile = () => {
    if (!signedUser?.profile) {
      setFeedback(true);
      UserService.getProfile()
        .then(setProfile)
        .finally(() => setFeedback(false));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchProfile, []);

  if (!profile) return null;

  const onSubmit = async (data: ProfileModel) => {
    try {
      setFeedback(true);
      await UserService.updateProfile(data);
      if (data.name !== signedUser?.name) {
        await UserService.updateUser({ name: data.name });
      }
      if (data.photo !== profile.photo) {
        UserService.setProfilePhoto(data.photo);
      }
      signedUser &&
        setUser({
          ...signedUser,
          name: data.name,
          profile: { ...profile, ...data },
        });
      setProfile({ ...profile, ...data });
      setFeedback(false);
      setOpenDialog(undefined);
    } catch (err) {
      setFeedback(false, err);
    }
  };

  return (
    <>
      <Box marginTop={2} />
      <ProfileMenu
        user={signedUser}
        profile={profile}
        onEdit={() => setOpenDialog(0)}
        onChangePassword={() => setOpenDialog(1)}
      />
      <ModalBox
        maxWidth={openDialog === 0 ? 'md' : 'xs'}
        open={openDialog !== undefined}
        onClose={() => setOpenDialog(undefined)}
        title={openDialog === 0 ? 'profile.header' : 'changePassword.header'}
      >
        {openDialog === 0 && (
          <ProfileForm
            t={t}
            error={error}
            loading={loading}
            onCancel={() => setOpenDialog(undefined)}
            onSubmit={onSubmit}
            profile={{ ...signedUser, ...profile }}
          />
        )}
        {openDialog === 1 && (
          <ChangePasswordForm onCancel={() => setOpenDialog(undefined)} />
        )}
      </ModalBox>
    </>
  );
};

export default Profile;
