import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { Image, Share } from '@mui/icons-material';

import { CourseModel } from '../../models/course.model';
import { useNotification } from '../../providers/NotificationProvider';
import ShareLink from './ShareLink';

const CourseViewHead = ({ course }: { course: CourseModel }) => {
  const { render, close } = useNotification();

  const handleShare = () => {
    render(<ShareLink link={location.href} onClose={close} />);
  };

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item>
          <Box
            width={145}
            height={145}
            borderRadius="16px"
            bgcolor="background.default"
            fontSize="5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {course.cover ? (
              <img
                src={course.cover}
                style={{
                  maxWidth: '100%',
                  height: '100%',
                  borderRadius: '16px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Image fontSize="inherit" color="disabled" />
            )}
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">{course.title}</Typography>
          <Box pt={1} />
          <Typography variant="subtitle1" noWrap>
            <Trans
              i18nKey="course.publishedAt"
              values={{ createdAt: moment(course.createdAt).format('LL') }}
            />
          </Typography>
          <Box pt={2}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              endIcon={<Share />}
              onClick={handleShare}
            >
              <Trans i18nKey="share" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CourseViewHead;
