import React, { ReactNode, useState } from 'react';
import { Card, Step, StepContent, StepLabel, Stepper } from '@mui/material';

export type StepperControls = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

type CustomStep = {
  label: string | ReactNode;
  content: (controls: StepperControls) => ReactNode;
  completed?: boolean;
};

interface VerticalStepperProps {
  steps: CustomStep[];
}

const VerticalStepper = ({ steps }: VerticalStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const goToNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Card>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map(({ label, completed, content }, idx) => (
          <Step key={idx} completed={completed}>
            <StepLabel onClick={() => setActiveStep(idx)}>{label}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              {content({ goToNextStep, goToPreviousStep })}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Card>
  );
};

export default VerticalStepper;
