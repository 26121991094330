import React from 'react';
import { Box, Divider } from '@mui/material';

const VerticalDivider = () => (
  <Box height="32px" width="1px" marginX={2}>
    <Divider orientation="vertical" />
  </Box>
);

export default VerticalDivider;
