import React from 'react';
import { TFunction } from 'i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';

import UploadImage from '../molecules/UploadImage';
import TextInput from '../atoms/TextInput';
import { CourseModel } from '../../models/course.model';
import { newCourseSchema } from '../../schemas/course.schema';
import { Trans } from 'react-i18next';
import Loading from '../atoms/Loading';
import RichTextInput from '../atoms/RichTextInput';
import ErrorMessage from '../atoms/ErrorMessage';

interface CourseFormProps {
  t: TFunction;
  loading?: boolean;
  course: Partial<CourseModel>;
  error?: string;
  onSubmit: (data: CourseModel) => void;
  onCancel: () => void;
}

const CourseForm = ({
  t,
  course,
  onCancel,
  onSubmit,
  loading,
  error,
}: CourseFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = useForm({
    resolver: yupResolver(newCourseSchema(t)),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm="auto">
          <UploadImage
            defaultImg={course.cover}
            onChange={(imageSrc) => setValue('cover', imageSrc)}
          />
          <input type="hidden" {...register('cover')} />
        </Grid>
        <Grid item xs={12} sm>
          <TextInput
            id="title"
            label={t<string>('course.title')}
            name="title"
            control={control}
            defaultValue={course?.title}
          />

          <RichTextInput
            id="description"
            label={t('course.description')}
            name="description"
            register={register}
            formState={formState}
            setValue={setValue}
            errors={errors}
            defaultValue={course?.description}
          />

          <TextInput
            id="price"
            label={t<string>('course.price')}
            name="price"
            control={control}
            defaultValue={course?.price?.toFixed(2)}
            type="tel"
            mask="currency"
            placeholder="0,00"
            startIcon={<>R$</>}
            setValue={setValue}
          />

          <ErrorMessage error={error} />
        </Grid>
      </Grid>
      <Box textAlign="right" marginTop={2}>
        <Button onClick={onCancel}>
          <Trans i18nKey="editCourse.cancel" />
        </Button>
        <Box marginRight={2} display="inline" />
        <Button color="primary" type="submit" disabled={loading}>
          <Trans i18nKey="editCourse.submit" />
          <Loading isLoading={loading} />
        </Button>
      </Box>
    </form>
  );
};

export default CourseForm;
