import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Button, Card, Typography } from '@mui/material';
import { CastForEducationOutlined } from '@mui/icons-material';

import GridBox from '../atoms/GridBox';
import NoDataImg from './../../assets/no-data.svg';

interface GetStartedCardProps {
  onClick?: () => void;
}

const GetStartedCard = ({ onClick }: GetStartedCardProps) => {
  return (
    <Card>
      <GridBox>
        <Box>
          <Typography variant="h6">
            <Trans i18nKey="home.noData.title" />
          </Typography>
          <Typography variant="subtitle2">
            <Trans i18nKey="home.noData.subtitle" />
          </Typography>
          <Box marginTop={2} />
          <Button
            color="secondary"
            startIcon={<CastForEducationOutlined />}
            onClick={onClick}
          >
            <Trans i18nKey="home.newCourse" />
          </Button>
        </Box>
        <Box>
          <img alt="No Data" src={NoDataImg} height={100} />
        </Box>
      </GridBox>
    </Card>
  );
};

export default GetStartedCard;
