import http from '../utils/http';
import { handleAPIError } from '../utils/error-handling';
import { HomeModel } from '../models/home.model';

const getHomeInfo = async (limit?: number): Promise<HomeModel> => {
  try {
    const { data } = await http.get<HomeModel>(
      `/home${limit ? `?limit=${limit}` : ''}`
    );
    return {
      teaching: {
        courses: [],
        drafts: [],
        stats: {
          courses: 0,
          students: 0,
        },
      },
      learning: {
        courses: [],
        stats: {
          courses: 0,
        },
      },
      ...data,
    };
  } catch (error) {
    throw handleAPIError(error);
  }
};

const HomeService = {
  getHomeInfo,
};

export default HomeService;
