import React from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { LearningModel } from '../../models/home.model';
import GridBox from '../atoms/GridBox';

interface LatestWatchedCardProps {
  latestWatched: LearningModel['latestWatched'];
  onResume: () => void;
}

const LatestWatchedCard = ({
  latestWatched,
  onResume,
}: LatestWatchedCardProps) => {
  const { course, chapters, lessons } = latestWatched || {};
  return (
    <Card>
      <GridBox>
        <Box flexGrow={1}>
          <Typography variant="subtitle2" color="inherit">
            {course?.title}
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            {chapters?.title} • {lessons?.title}
          </Typography>
        </Box>
        <IconButton size="small" onClick={onResume}>
          <PlayArrow color="primary" />
        </IconButton>
      </GridBox>
    </Card>
  );
};

export default LatestWatchedCard;
