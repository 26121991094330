import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const ProtectedRoutes = () => {
  const { signedUser } = useAuth();
  const location = useLocation();

  if (!signedUser) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
