import React from 'react';
import { Link, Typography } from '@mui/material';

const Copyright = (): JSX.Element => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://facilittei.com/">
      Facilittei
    </Link>{' '}
    {new Date().getFullYear()}.
  </Typography>
);

export default Copyright;
