export interface Config {
  user_id?: string;
  debug_mode?: boolean;
  send_page_view?: boolean;
}

export interface PageViewEvent {
  page_title: string;
  page_path: string,
}

export enum AuthMethod {
  EmailAndPassword = 'Email & Password',
}

export interface AuthEvent {
  method: AuthMethod;
}

export interface Items {
    item_id: string;
    affiliation?: string;
    item_name?: string;
}

export interface ItemEvent {
  currency: 'BRL';
  value: number;
  items: Items[];
}

export interface PurchaseEvent {
  currency: 'BRL';
  transaction_id: string;
  value: number;
  tax: number;
  items: Items[];
}
