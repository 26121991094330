import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Hidden,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import FullLogo from '../../assets/full-logo-dark.svg';
import Logo from '../../assets/logo-dark.svg';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import MenuPopover, { OpenMenuProps } from './MenuPopover';
import AppMenu from '../organisms/AppMenu';

const AppToolbar = () => {
  const { signedUser } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box display="flex" justifyContent="center">
      <AppBar position="fixed" color="default">
        <Box width={1} maxWidth={theme.breakpoints.values.lg}>
          <Toolbar>
            <Box flexGrow={1}>
              <ButtonBase disableRipple href="/">
                <img alt="logo" src={xs ? Logo : FullLogo} height={30} />
              </ButtonBase>
            </Box>
            {!signedUser ? (
              <Button
                className="action"
                variant="outlined"
                startIcon={<Person />}
                href="/sign-up"
              >
                {t('createAccount')}
              </Button>
            ) : (
              <MenuPopover
                OpenComponent={MenuButton}
                ContentComponent={AppMenu}
              />
            )}
          </Toolbar>
        </Box>
      </AppBar>
      <Box
        flexGrow={1}
        padding={xs ? 1 : 2}
        paddingTop={3}
        paddingBottom={xs ? 8 : 4}
        marginTop={`${theme.mixins.toolbar.minHeight}px`}
        maxWidth={theme.breakpoints.values.lg}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

const MenuButton = ({ handleOpen, open }: OpenMenuProps) => {
  const { signedUser } = useAuth();

  return (
    <Box borderRadius="8px" overflow="hidden">
      <ButtonBase onClick={handleOpen}>
        <Box display="flex" alignItems="center" paddingY={1 / 2}>
          <Avatar src={signedUser?.profile?.photo}>
            {signedUser?.name[0]}
          </Avatar>
          <Hidden only="xs">
            <Box maxWidth={160} paddingLeft={1}>
              <Typography variant="subtitle2" align="left" noWrap>
                {signedUser?.name}
              </Typography>
              <Typography variant="caption" display="block" noWrap>
                {signedUser?.email}
              </Typography>
            </Box>
            <Box paddingLeft={1} color="text.secondary">
              {!open ? (
                <KeyboardArrowDown color="inherit" />
              ) : (
                <KeyboardArrowUp color="secondary" />
              )}
            </Box>
          </Hidden>
        </Box>
      </ButtonBase>
    </Box>
  );
};

export default AppToolbar;
