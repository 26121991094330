import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Grid } from '@mui/material';
import {
  BookmarkBorder,
  LocalLibraryOutlined,
  MenuBook,
  Public,
} from '@mui/icons-material';

import { useNotification } from '../providers/NotificationProvider';
import NoData from '../components/organisms/NoData';
import FloatingButton from '../components/atoms/FloatingButton';
import NewCourse from './NewCourse';
import TabsContainer from '../components/molecules/TabsContainer';
import CourseCard from '../components/organisms/CourseCard';
import DraftCard from '../components/organisms/DraftCard';
import { HomeModel } from '../models/home.model';
import HomeService from '../services/home.service';
import ProgressBox from '../components/atoms/ProgressBox';
import Empty from '../components/molecules/Empty';
import CourseService from '../services/course.service';
import FavoritesCard from '../components/organisms/FavoritesCard';
import { LessonModel } from '../models/lesson.model';
import { ViewMode } from '../models/user.model';

interface MyCoursesProps {
  view?: ViewMode;
}

const MyCourses = ({ view = ViewMode.Learning }: MyCoursesProps) => {
  const navigate = useNavigate();
  const { render, close } = useNotification();
  const [state, setState] = useState<HomeModel>();
  const [favorites, setFavorites] = useState<LessonModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    HomeService.getHomeInfo()
      .then(setState)
      .finally(() => setLoading(false));

    if (view === ViewMode.Learning) {
      CourseService.getAllFavorites().then(setFavorites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <ProgressBox text="myCourses.loading" />;
  }

  if (!state?.teaching && !state?.learning) {
    return <NoData />;
  }

  const tabs =
    view === ViewMode.Teaching
      ? [
          {
            label: 'online',
            icon: <Public />,
          },
          {
            label: 'draft',
            icon: <MenuBook />,
          },
        ]
      : [
          {
            label: 'all',
            icon: <LocalLibraryOutlined />,
          },
          {
            label: 'favorites',
            icon: <BookmarkBorder />,
          },
        ];

  const openNewCourse = () => {
    render(
      <Dialog open={true} onClose={close} fullWidth maxWidth="sm">
        <NewCourse navigate={navigate} />
      </Dialog>
    );
  };

  return (
    <>
      {view === ViewMode.Learning ? (
        <TabsContainer tabs={tabs}>
          <Grid container spacing={2}>
            {!state?.learning?.courses?.length && (
              <Empty title="myCourses.emptyEnrolled" />
            )}
            {state?.learning?.courses?.map((course) => (
              <Grid key={course.id} item xs={12} sm={12} md={6}>
                <CourseCard
                  course={course}
                  onViewCourse={() => navigate(`/course/${course.id}/${course.slug}`)}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            {!favorites.length && <Empty title="myCourses.emptyFavorited" />}
            {favorites.map((lesson) => (
              <Grid key={lesson.id} item xs={12} sm={12} md={6}>
                <FavoritesCard
                  lesson={lesson}
                  onResume={() =>
                    navigate(
                      `/chapter/${lesson?.chapter.id}/lesson/${lesson.id}`
                    )
                  }
                />
              </Grid>
            ))}
          </Grid>
        </TabsContainer>
      ) : (
        <>
          <TabsContainer tabs={tabs}>
            <Grid container spacing={2}>
              {!state?.teaching?.courses?.length && (
                <Empty title="myCourses.empty" />
              )}
              {state?.teaching?.courses?.map((course) => (
                <Grid key={course.id} item xs={12} sm={12} md={6}>
                  <CourseCard
                    course={course}
                    onViewCourse={() => navigate(`/course/${course.id}/${course.slug}`)}
                    view={ViewMode.Teaching}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2}>
              {!state?.teaching?.drafts?.length && (
                <Empty title="myCourses.emptyDrafts" />
              )}
              {state?.teaching?.drafts?.map((course) => (
                <Grid key={course.id} item xs={12} sm={12} md={6}>
                  <DraftCard
                    course={course}
                    onClick={() => navigate(`/edit-course/${course.id}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </TabsContainer>
          <FloatingButton label="home.fab" onClick={openNewCourse} />
        </>
      )}
    </>
  );
};

export default MyCourses;
