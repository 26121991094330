import { TFunction } from 'i18next';
import * as yup from 'yup';

export const enrollSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t('validations.name.required')),
    email: yup
      .string()
      .required(t('validations.email.required'))
      .email(t('validations.email.invalid')),
  });
