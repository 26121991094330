import React, { ChangeEvent, ReactNode, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import DragDropBox, { DragDropBoxProps } from '../atoms/DragDropBox';

export interface AccordionData {
  title: string;
  info?: string;
  content?: ReactNode;
}

interface AccordionListProps {
  data: AccordionData[];
  expandAll?: boolean;
  active?: number;
  actions?: (index: number) => ReactNode;
  onReorder?: DragDropBoxProps['onReorder'];
}

const AccordionList = ({
  data,
  active,
  actions,
  onReorder,
  expandAll,
}: AccordionListProps) => {
  const [expanded, setExpanded] = useState<number | false>(
    active !== undefined ? active : false
  );
  const handleChange =
    (panel: number) => (_: ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getAccordionProps = (index: number) => {
    if (expandAll) {
      return {};
    }

    return {
      expanded: expanded === index,
      onChange: handleChange(index),
    };
  };

  const accordions = data.map((accordion, index) => (
    <Accordion key={`${index}`} elevation={0} {...getAccordionProps(index)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          width={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="text.disabled"
          marginRight={1}
        >
          <Box display="flex" alignItems="center">
            {onReorder && (
              <Box display="flex" alignItems="center" paddingRight={1}>
                <DragIndicator color="disabled" />
              </Box>
            )}

            <Typography color={expanded === index ? 'primary' : 'textPrimary'}>
              {accordion.title}
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              {accordion.info && (
                <Box display="inline" paddingX={1}>
                  •
                </Box>
              )}
              {accordion.info?.toUpperCase()}
            </Typography>
          </Box>
          {actions && actions(index)}
        </Box>
      </AccordionSummary>
      <Box>
        {accordion.content}
        <Divider />
      </Box>
    </Accordion>
  ));

  return (
    <>
      {onReorder ? (
        <DragDropBox onReorder={onReorder}>{accordions}</DragDropBox>
      ) : (
        accordions
      )}
    </>
  );
};

export default AccordionList;
